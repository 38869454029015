import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import dataService from "components/Common/dataService";

interface ParamTypes {
  id: string;
}

export const FirmwareLog = (params: any) => {
  const { id } = useParams<ParamTypes>();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    axios
      .get("/api/firmwares-ota-historic/" + id + "/")
      .then((response: any) => setData(response?.data));
  }, [id]);

  const getDeviceType = (deviceTypeId: number) => {
    let deviceType = dataService
      .getData("deviceTypes")
      .find((deviceType: any) => deviceType.id === deviceTypeId);
    return deviceType?.name || "";
  };

  return (
    <div>
      <div className="block">
        <h2>
          <Trans>Fiche détail logs</Trans>
        </h2>

        {data && (
          <>
            <h3>Informations</h3>
            <Row>
              <Col sm={3}>Type Btoolz</Col>
              <Col sm={9}>{getDeviceType(data?.device__device_type)}</Col>
            </Row>
            <Row>
              <Col sm={3}>Numéro de série Btoolz</Col>
              <Col sm={9}>{data?.device__serial_number}</Col>
            </Row>
            <Row>
              <Col sm={3}>Utilisateur associé</Col>
              <Col sm={9}>{data?.device__user__email}</Col>
            </Row>
            <Row>
              <Col sm={3}>Date de mise à jour</Col>
              <Col sm={9}>
                {moment(data?.created_at).format("DD/MM/YYYY à HH:mm:ss")}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>Version firmware</Col>
              <Col sm={9}>{data?.firmware__version_number}</Col>
            </Row>
            <Row>
              <Col sm={3}>Logs</Col>
              <Col sm={9}>
                <pre>{data?.logs}</pre>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};
