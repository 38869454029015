import React from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import {
  faUserCircle,
  faPaperPlane,
  faCog,
  faPowerOff,
  faAngleRight,
  faAngleDown,
  faBars,
  faChartBar,
  faMicrochip,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Sidebar.scss";
import { auth } from "../auth";
import packageJson from "../../package.json";
import axios from "axios";
import dataService from "./Common/dataService";

interface Props {}

interface State {
  activeKey: string;
  menuIsExpanded: boolean;
  elementHovered: string;
  apiVersion: string;
  appVersion: string;
  codec: string;
  environment: string;
}

class Sidebar extends React.Component<Props, State> {
  public state: State = {
    activeKey: "admin",
    menuIsExpanded: true,
    elementHovered: "",
    apiVersion: "",
    appVersion: packageJson?.version,
    codec: "",
    environment: "",
  };

  public componentDidMount() {
    // Load expanded menu pref stored in localstorage
    let menuIsExpanded = localStorage.getItem("menuIsExpanded") || "true";
    menuIsExpanded = JSON.parse(menuIsExpanded);
    if (typeof menuIsExpanded === "boolean") {
      this.setState({ menuIsExpanded });
      this.setExpandedCssClasses(menuIsExpanded);
    }

    axios.get("/api/version/").then((response) =>
      this.setState({
        apiVersion: response?.data?.version,
        environment: response?.data?.environment,
      })
    );

    let encoderSpecs = dataService.getData("encodersSpecs");
    this.setState({
      codec: `${encoderSpecs?.commit} (${encoderSpecs?.version})`,
    });
  }

  public handleToggleMenu = (activeKey: string) => {
    activeKey = this.state.activeKey === activeKey ? "" : activeKey;
    this.setActiveKey(activeKey);
  };

  public toggleBurger() {
    let menuIsExpanded = !this.state.menuIsExpanded;
    this.setState({ menuIsExpanded });
    localStorage.setItem("menuIsExpanded", menuIsExpanded + "");
    this.setExpandedCssClasses(menuIsExpanded);
  }

  public setExpandedCssClasses(menuIsExpanded: boolean) {
    if (menuIsExpanded) {
      document.body.classList.add("sidebar-expanded");
      document.body.classList.remove("sidebar-collapsed");
    } else {
      document.body.classList.remove("sidebar-expanded");
      document.body.classList.add("sidebar-collapsed");
    }
  }

  public handleHoverOff() {
    this.setState({ elementHovered: "" });
  }

  public handleHoverOn(activeKey: string) {
    this.setState({ elementHovered: activeKey });

    if (!this.state.menuIsExpanded) {
      this.setActiveKey(activeKey);
    }
  }

  private setActiveKey(activeKey: string) {
    this.setState({ activeKey });
    localStorage.setItem("activeKey", activeKey);
  }

  public render() {
    return (
      <div className={"sidebar " + this.state.environment}>
        <div className="logo">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/btoolzup-logo.png"}
              alt="B-Link"
            />
          </Link>
        </div>
        <div id="burger" onClick={() => this.toggleBurger()}>
          <Button>
            <FontAwesomeIcon icon={faBars} size="lg" />
          </Button>
        </div>
        <div className="welcome">
          <Container fluid>
            <Row className="align-items-center">
              <Col md="auto">
                <div className="icon">
                  {auth.user?.photo ? (
                    <div
                      className="avatar"
                      style={{
                        backgroundImage: 'url("' + auth.user.photo + '"',
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUserCircle} size="lg" />
                  )}
                </div>
              </Col>
              <Col>
                <p>
                  <Trans>Bienvenue</Trans>
                </p>
                <p>{auth.user ? auth.user.name : ""}</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="sidebar-wrapper">
          <div className="sidebar-scroll">
            <Nav className="flex-column">
              <Accordion className="menu" activeKey={this.state.activeKey}>
                <Card
                  onClick={() => {
                    this.handleHoverOn("firmwares");
                  }}
                  onMouseEnter={() => {
                    this.handleHoverOn("firmwares");
                  }}
                  onMouseLeave={() => {
                    this.handleHoverOff();
                  }}
                  style={{ paddingLeft: "12px", fontWeight: 600 }}
                >
                  <Card.Header>
                    <Link to="/firmwares">
                      <div className="icon">
                        <FontAwesomeIcon icon={faMicrochip} />
                      </div>
                      <span>
                        <Trans>Firmwares</Trans>
                      </span>
                    </Link>
                  </Card.Header>
                </Card>

                <Card
                  onClick={() => {
                    this.handleHoverOn("parc");
                  }}
                  onMouseEnter={() => {
                    this.handleHoverOn("parc");
                  }}
                  onMouseLeave={() => {
                    this.handleHoverOff();
                  }}
                >
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="firmwares-ota-historic"
                      onClick={() => {
                        this.handleToggleMenu("firmwares-ota-historic");
                      }}
                    >
                      <div className="icon">
                        <FontAwesomeIcon icon={faSitemap} />
                      </div>
                      <span>
                        <Trans>Parc BToolz</Trans>
                      </span>
                      <span className="arrow-right">
                        <FontAwesomeIcon
                          icon={
                            this.state.activeKey === "firmwares-ota-historic"
                              ? faAngleDown
                              : faAngleRight
                          }
                        ></FontAwesomeIcon>
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="firmwares-ota-historic">
                    <Card.Body
                      className={`${
                        this.state.elementHovered === "firmwares-ota-historic"
                          ? "hover"
                          : ""
                      }`}
                    >
                      <Link to="/firmwares-ota-historic">
                        <Trans>Parc</Trans>
                      </Link>
                      <Link to="/firmwares-ota-historic/users">
                        <Trans>Utilisateurs</Trans>
                      </Link>
                      <Link to="/firmwares-ota-historic/devices">
                        <Trans>Devices</Trans>
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card
                  onClick={() => {
                    this.handleHoverOn("sends");
                  }}
                  onMouseEnter={() => {
                    this.handleHoverOn("sends");
                  }}
                  onMouseLeave={() => {
                    this.handleHoverOff();
                  }}
                  style={{ paddingLeft: "12px", fontWeight: 600 }}
                >
                  <Card.Header>
                    <Link to="/sends">
                      <div className="icon">
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </div>
                      <span>
                        <Trans>Envois mails</Trans>
                      </span>
                    </Link>
                  </Card.Header>
                </Card>

                <Card
                  onClick={() => {
                    this.handleHoverOn("stats");
                  }}
                  onMouseEnter={() => {
                    this.handleHoverOn("stats");
                  }}
                  onMouseLeave={() => {
                    this.handleHoverOff();
                  }}
                  style={{ paddingLeft: "12px", fontWeight: 600 }}
                >
                  <Card.Header>
                    <Link to="/statistics">
                      <div className="icon">
                        <FontAwesomeIcon icon={faChartBar} />
                      </div>
                      <span>
                        <Trans>Statistiques</Trans>
                      </span>
                    </Link>
                  </Card.Header>
                </Card>

                {/* {auth.canAccess("ADMIN_MENU") && ( */}
                <Card
                  onClick={() => {
                    this.handleHoverOn("admin");
                  }}
                  onMouseEnter={() => {
                    this.handleHoverOn("admin");
                  }}
                  onMouseLeave={() => {
                    this.handleHoverOff();
                  }}
                >
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="admin"
                      onClick={() => {
                        this.handleToggleMenu("admin");
                      }}
                    >
                      <div className="icon">
                        <FontAwesomeIcon icon={faCog} />
                      </div>
                      <span>
                        <Trans>Administration</Trans>
                      </span>
                      <span className="arrow-right">
                        <FontAwesomeIcon
                          icon={
                            this.state.activeKey === "admin"
                              ? faAngleDown
                              : faAngleRight
                          }
                        ></FontAwesomeIcon>
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="admin">
                    <Card.Body
                      className={`${
                        this.state.elementHovered === "admin" ? "hover" : ""
                      }`}
                    >
                      <Link to="/admin/users">
                        <Trans>Utilisateurs</Trans>
                      </Link>
                      <Link to="/admin/groups">
                        <Trans>Groupes</Trans>
                      </Link>
                      <Link to="/admin/sections">
                        <Trans>Sections</Trans>
                      </Link>
                      <Link to="/admin/messages">
                        <Trans>Messages</Trans>
                      </Link>
                      <Link to="/admin/logs">
                        <Trans>Logs</Trans>
                      </Link>
                      <Link to="/admin/device-types">
                        <Trans>Type device</Trans>
                      </Link>
                      <Link to="/admin/companies">
                        <Trans>Sociétés</Trans>
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/* )} */}
              </Accordion>
            </Nav>
          </div>
        </div>
        <span className="logout">
          <Button className="icon" variant="light" onClick={auth.logout}>
            <FontAwesomeIcon icon={faPowerOff} />
          </Button>
        </span>
      </div>
    );
  }
}

export default Sidebar;
