const colors: any = {
  0: {
    bgColor: "rgba(177,212,236,1)",
    color: "#fff",
  },
  1: {
    bgColor: "rgba(245,196,146,1)",
    color: "#fff",
  },
  2: {
    bgColor: "rgba(245,196,146,0.5)",
    color: "#fff",
  },
  3: {
    bgColor: "rgba(15,137,172,1)",
    color: "#fff",
  },
  4: {
    bgColor: "rgba(236,125,141,1)",
    color: "#fff",
  },
  5: {
    bgColor: "rgba(217,172,155, 1)",
    color: "#fff",
  },
  6: {
    bgColor: "rgba(97,219,124,0.7)",
    color: "#fff",
  },
  7: {
    bgColor: "rgba(236,125,141,0.7)",
    color: "#fff",
  },
  8: {
    bgColor: "rgba(73,184,97,1)",
    color: "#fff",
  },
  9: {
    bgColor: "rgba(231,231,231,1)",
    color: "#fff",
  },
};

class ColorCharts {
  public getColor = (index: number) => {
    if (colors[index]) {
      return colors[index];
    } else {
      // generate random color
      return {
        bgColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
      };
    }
  };
}

export let colorCharts = new ColorCharts();
