import React from "react";
import { Button } from "react-bootstrap";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import dataService from "../Common/dataService";

export const Sends = (params: any) => {
  const endpoint = "/api/email-messages/";

  let listFields = [
    {
      name: "created_at",
      label: "Date d'envoi",
      transform: (value: any) => {
        return moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
      },
    },
    {
      name: "device_type",
      label: "Device type cible",
      transform: (value: any) => {
        let deviceType = dataService
          .getData("deviceTypes")
          .find((deviceType: any) => deviceType.id === value);
        return deviceType?.name || "";
      },
    },
    {
      name: "firmware",
      label: "Firmware update",
    },
    {
      name: "recipients_count",
      label: "Nb utilisateurs cible",
      className: "text-center",
    },
  ];

  let searchFields = [
    {
      name: "sending_date",
      label: "Date",
      type: "datepicker",
    },
    {
      name: "device_type",
      label: "Devices",
      options: {
        identifier: "id",
        source: "/api/device-types/",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];

  let actions = [
    {
      name: "view",
      label: "Voir l'envoi",
      icon: <FontAwesomeIcon icon={faSearch} />,
      url: "/sends/view/:id",
    },
  ];

  return (
    <div>
      <div className="block">
        <div className="button-add-user">
          <Link to="/sends/new">
            <Button>
              <FontAwesomeIcon icon={faPlusCircle} /> Nouvel envoi
            </Button>
          </Link>
        </div>
        <h2>
          <Trans>Envois de mails</Trans>
        </h2>

        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
        />
      </div>
    </div>
  );
};
