import React from "react";
import axios from "axios";
import "./AutocompleteField.scss";
import AsyncSelect from "react-select/async";

interface Props {
  field: { options?: any };
  onSelect(value: any): void;
  value: any;
  isDisabled?: boolean;
  defaultOptions?: any
}

interface State {
  inputValue: string;
  relatedValues: any;
}

class AutocompleteField extends React.Component<Props, State> {
  public state: State = {
    inputValue: "",
    relatedValues: null,
  };

  cancelToken: any;

  public loadOptions = (inputValue: string) => {
    let endpoint = this.props.field.options?.source;
    let searchField = this.props.field.options?.label || "name";
    let formatResponse = this.props.field.options?.formatResponse;
    let identifier = this.props.field.options?.identifier || "id";

    // If autocomplete field is dependent of other field value
    let relatedSearch = "";
    if (
      this.props.field.options?.relatedTo &&
      typeof this.props.field.options?.relatedTo?.getValues === "function"
    ) {
      let values = this.props.field.options?.relatedTo?.getValues();
      let filterName = this.props.field.options?.relatedTo.label;
      if (
        filterName.substring(filterName.length, filterName.length - 4) ===
        "__in"
      ) {
        relatedSearch += "&" + filterName + "=" + values.join(",");
      } else {
        values.forEach((value: any) => {
          relatedSearch += "&" + filterName + "=" + value;
        });
      }
    }

    // Add ? if necessary for additional filters
    endpoint = endpoint.indexOf("?") === -1 ? endpoint + "?" : endpoint;

    // Is active
    let isActiveCriteria = this.props.field.options.isActive
      ? "&is_active=true"
      : "";

    return new Promise((resolve, reject) => {
      //Check if there are any previous pending requests
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      this.cancelToken = axios.CancelToken.source();
      axios
        .get(
          endpoint +
          searchField +
          "=" +
          inputValue +
          relatedSearch +
          isActiveCriteria,
          {
            cancelToken: this.cancelToken.token,
          }
        )
        .then(
          (response: any) => {
            let data = response.data;
            if (data.results) {
              data.results = data.results.map((result: any) => {
                if (formatResponse && typeof formatResponse === "function") {
                  return formatResponse(result);
                } else {
                  return {
                    label: result[searchField],
                    value: result[identifier],
                  };
                }
              });
              resolve(data.results);
            }
          },
          (err) => { }
        );
    });
  };

  public handleInputChange = (newValue: string) => {
    const inputValue = newValue;
    this.setState({ inputValue });
    return inputValue;
  };

  public onChange = (data: any) => {
    this.props.onSelect(data);
  };

  public static getDerivedStateFromProps(props: any, state: any) {
    if (
      typeof props.field.options?.relatedTo?.getValues === "function" &&
      props.field.options?.relatedTo?.getValues() &&
      JSON.stringify(state.relatedValues) !==
      JSON.stringify(props.field.options?.relatedTo?.getValues())
    ) {
      return {
        relatedValues: props.field.options?.relatedTo?.getValues(),
      };
    }
    return null;
  }

  public render() {
    let searchIsMulti = true;
    if (this.props.field.options?.searchIsMulti !== undefined) {
      searchIsMulti = this.props.field.options?.searchIsMulti;
    }

    const customStyles = {
      loadingIndicator: (provided: any) => {
        return {
          ...provided,
          color: "#2780E3",
        };
      },
      loadingMessage: (provided: any) => {
        return {
          ...provided,
          color: "#2780E3",
          fontWeight: "bold",
        };
      },
    };

    return (
      <AsyncSelect
        key={JSON.stringify(this.state.relatedValues)}
        isMulti={searchIsMulti}
        className="async-select"
        classNamePrefix="select"
        isClearable
        cacheOptions
        defaultOptions={this.props.defaultOptions || true}
        value={this.props.value}
        loadOptions={this.loadOptions}
        onInputChange={this.handleInputChange}
        onChange={this.onChange}
        loadingMessage={() => "Chargement en cours..."}
        noOptionsMessage={() => "Aucun résultat"}
        placeholder=""
        styles={customStyles}
        isDisabled={
          typeof this.props.isDisabled === "boolean"
            ? this.props.isDisabled
            : false
        }
      />
    );
  }
}

export default AutocompleteField;
