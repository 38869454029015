import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import SearchForm from "../Common/SearchForm";
import { StatPieChart } from "./StatPieChart";
import axios from "axios";
import { colorCharts } from "./ColorCharts";
import "./Statistics.scss";

export const Statistics = () => {
  const [filters, setFilters] = useState("");
  const [pieData, setPieData] = useState<any[]>([]);
  const [pieLegends, setPieLegends] = useState<any>({});

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchData = () => {
    axios
      .get("/api/firmwares-ota-historic-stats/?" + filters)
      .then((response) => {
        let stats = response?.data?.stats;

        let tempPieLegends: any = {};
        let tempPieData: any = {};
        Object.keys(stats).forEach((deviceType) => {
          tempPieData[deviceType] = stats[deviceType].map((stat: any) => {
            return { name: stat.firmware, value: stat.count };
          });

          if (!tempPieLegends[deviceType]) {
            tempPieLegends[deviceType] = tempPieLegends[deviceType] || {};
            stats[deviceType].forEach((statFirmware: any, index: number) => {
              let color = colorCharts.getColor(index);
              tempPieLegends[deviceType][index] = {
                label: statFirmware.firmware,
                color: color.color,
                bgColor: color.bgColor,
              };
            });
          }
        });

        setPieData(tempPieData);
        setPieLegends(tempPieLegends);
      });
  };

  let searchFields = [
    {
      name: "device_type",
      label: "Types de device",
      options: {
        identifier: "id",
        source: "/api/device-types/",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];

  return (
    <div className="block">
      <h2>
        <Trans>Statistiques de gestion de parc</Trans>
      </h2>
      <div className="block">
        <SearchForm
          filtersChange={(filters: any) => {
            setFilters(filters);
          }}
          fields={searchFields}
        />
      </div>

      <div className="charts">
        {pieData &&
          Object.keys(pieData).map((deviceType: any) => {
            return (
              <StatPieChart
                key={deviceType}
                data={pieData[deviceType]}
                legends={pieLegends[deviceType]}
                displayMode={"values"}
                title={deviceType}
              />
            );
          })}
      </div>
    </div>
  );
};
