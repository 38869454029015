import React, { useEffect } from "react";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExchangeAlt,
  faMobileAlt,
  faPortrait,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import dataService from "../Common/dataService";

interface ParamTypes {
  id: string;
}

export const FirmwareOTAHistoric = (params: any) => {
  const endpoint = "/api/firmwares-ota-historic/";
  const history = useHistory();

  useEffect(() => { });

  let listFields = [
    {
      name: "firmware__name",
      label: "Firmware",
      transform: (value: any, object: any) => {
        return <Link to={"/firmwares/edit/" + object.firmware}>{value}</Link>;
      },
    },
    {
      name: "device__user__email",
      label: "Email utilisateur",
    },
    {
      name: "device__device_type",
      label: "Type device",
      transform: (value: any) => {
        let deviceType = dataService
          .getData("deviceTypes")
          .find((deviceType: any) => deviceType.id === value);
        return deviceType?.name || value;
      },
    },
    {
      name: "finalization",
      label: "Finalisation",
      className: "text-center",
      options: { width: "100px" },
      transform: (finalization: any) => {
        return finalization ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        );
      },
    },
    {
      name: "created_at",
      label: "Date MAJ",
      transform: (value: any, o: any) => {
        return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
      },
      orderable: true,
      options: { width: "220px" },
    },
  ];

  let searchFields = [
    {
      name: "user",
      label: "Utilisateur",
      options: {
        identifier: "id",
        source: "/api/app-users/",
        label: "name",
      },
    },
    {
      name: "firmware",
      label: "Firmware",
      options: {
        identifier: "id",
        source: "/api/firmwares/",
        label: "name",
      },
    },
    {
      name: "serial_number",
      label: "Numéro de série",
      type: "text",
    },
  ];

  let actions = [
    {
      name: "view_user",
      label: "Voir l'utilisateur",
      icon: <FontAwesomeIcon icon={faPortrait} />,
      onClick: (value: any) => {
        history.push(
          "/firmwares-ota-historic/users/edit/" + value.device__user
        );
      },
    },
    {
      name: "devices",
      label: "Devices",
      icon: <FontAwesomeIcon icon={faMobileAlt} />,
      onClick: (value: any) => {
        history.push(
          "/firmwares-ota-historic/devices?user=" + value.device__user
        );
      },
    },
    {
      name: "log",
      label: "Logs",
      icon: <FontAwesomeIcon icon={faExchangeAlt} />,
      onClick: (value: any) => {
        history.push("/firmwares-ota-historic/logs/" + value.firmware + "/");
      },
    },
  ];

  return (
    <div>
      <div className="block">
        <h2>
          <Trans>Parc BToolz - mises à jour</Trans>
        </h2>

        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
        />
      </div>
    </div>
  );
};
