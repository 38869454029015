import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.scss";
import { LoggedInRoute } from "./auth";
import DashboardLayout from "./components/DashboardLayout";
import { Callback } from "./components/Login";
import { Loader } from "./components/Common/Loader";

type AppProps = {};
type AppState = {};

class App extends React.Component<AppProps, AppState> {
  render() {
    return (
      <>
        <Loader />
        <BrowserRouter>
          <Switch>
            <Route path="/callback" component={Callback} />
            <LoggedInRoute path="/" component={DashboardLayout} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
