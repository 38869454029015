import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { Row, Col, Form as FormBootstrap } from "react-bootstrap";
import Select from "react-select";
import dataService from "../Common/dataService";
import "./FirmwareForm.scss";
import { auth } from "../../auth";
import fr from "date-fns/locale/fr";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { notificationService } from "components/Common/Notification";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface ParamTypes {
  id: string;
}

interface Firmware {
  device_types?: number[];
  name?: string;
  download_url?: string;
  version_number?: string;
  crc?: string;
  comment?: string;
  availability_date?: string;
  is_active?: boolean;
  id?: number;
  created_at?: number;
  updated_at?: number;
}

export const FirmwareForm = (params: any) => {
  const { id } = useParams<ParamTypes>();

  const [firmware, setFirmware] = useState<Firmware>({});
  let history = useHistory();

  useEffect(() => {
    if (params.action === "edit" && id) {
      axios.get("/api/firmwares/" + id + "/").then((response) => {
        let loadedFirmware = response.data;

        loadedFirmware.device_types = loadedFirmware.device_types.map(
          (device_type: number) => {
            return deviceTypes.find(
              (deviceType: any) => deviceType.value === device_type
            );
          }
        );
        setFirmware(loadedFirmware);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const deviceTypes = dataService
    .getData("deviceTypes", { is_active: true })
    .map((group: any) => {
      return { value: group.id, label: group.name };
    });

  const readBinaryFile = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFile = async (e: any) => {
    let selectedFile = e.target.files?.length ? e.target.files[0] : null;
    if (selectedFile) {
      let file = await readBinaryFile(selectedFile);
      // console.log("file", file);
      axios({
        method: "PUT",
        url: "/api/firmware-upload/",
        headers: {
          "Content-Type": "application/octet-stream",
          "Content-Disposition": `attachment; filename=${selectedFile.name || "firmware.bin"
            }`,
        },
        data: file,
      }).then((response) => {
        let data = response?.data;
        if (data.version && data.url) {
          setFirmware({
            ...firmware,
            ...{ version_number: data.version, crc: data.crc, download_url: data.url },
          });
        }
      });
    }
  };

  const saveFirmware = (firmwareToSave: Firmware) => {
    firmwareToSave.availability_date = moment(
      firmwareToSave.availability_date
    ).format("YYYY-MM-DD");
    firmwareToSave.device_types =
      firmwareToSave && firmwareToSave.device_types
        ? firmwareToSave.device_types.map((deviceType: any) => deviceType.value)
        : [];

    if (!firmwareToSave.id) {
      axios.post("/api/firmwares/", firmwareToSave).then(() => {
        notificationService.show({
          title: "Succès",
          message: "Le firmware a été créé avec succès!",
          type: "success",
        });

        setTimeout(() => {
          history.push("/firmwares");
        }, 3000);
      });
    } else {
      axios
        .put("/api/firmwares/" + firmwareToSave.id + "/", firmwareToSave)
        .then(() => {
          notificationService.show({
            title: "Succès",
            message: "Le firmware a été modifié avec succès!",
            type: "success",
          });

          setTimeout(() => {
            history.push("/firmwares");
          }, 3000);
        });
    }
  };

  return (
    <div className="block">
      <h2>
        {firmware.id
          ? "Modifier le firmware `" + firmware.name + "`"
          : "Ajouter un firmware"}
      </h2>

      {((params.action === "edit" && firmware.id) || params.action === "add") && (
        <Formik
          initialValues={firmware}
          enableReinitialize={false}
          onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
            setTimeout(() => {
              let newFirmware = { ...firmware, ...values };
              setFirmware(newFirmware);
              saveFirmware(newFirmware);
              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            getFieldProps,
            isSubmitting,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form>
              <FormBootstrap.Group as={Row} controlId="device-type">
                <FormBootstrap.Label column sm="4">
                  Type d'équipement
              </FormBootstrap.Label>
                <Col sm="8">
                  <div
                    className={
                      (touched.deviceTypes || isSubmitting) && errors.deviceTypes
                        ? "select-container is-invalid"
                        : "select-container"
                    }
                  >
                    <Select
                      options={deviceTypes}
                      {...getFieldProps("device_types")}
                      onChange={(values) => setFieldValue("device_types", values)}
                      isMulti={true}
                      loadingMessage={() => "Chargement en cours..."}
                      noOptionsMessage={() => "Aucune valeur à sélectionner"}
                      placeholder=""
                    />
                  </div>
                  <FormBootstrap.Control.Feedback type="invalid">
                    {errors.deviceTypes}
                  </FormBootstrap.Control.Feedback>
                </Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="name">
                <FormBootstrap.Label column sm="4">
                  Nom
              </FormBootstrap.Label>
                <Col sm="8">
                  <FormBootstrap.Control
                    type="text"
                    value={values?.name || ""}
                    {...getFieldProps("name")}
                  />
                </Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="availability_date">
                <FormBootstrap.Label column sm="4">
                  Mise à disposition
              </FormBootstrap.Label>
                <Col sm="8">
                  <DatePicker
                    selected={
                      values.availability_date
                        ? new Date(values.availability_date)
                        : null
                    }
                    onChange={(date: any) => {
                      setFieldValue("availability_date", date);
                    }}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="firmware">
                <FormBootstrap.Label column sm="4">
                  Firmware
              </FormBootstrap.Label>
                <Col sm="8">
                  {firmware.download_url ? (
                    <>
                      <a
                        href={firmware.download_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {firmware.download_url}
                      </a>
                      <FontAwesomeIcon
                        className="ml-3 link"
                        icon={faTimes}
                        onClick={() => {
                          setFirmware({
                            ...firmware,
                            ...{ download_url: "", crc: "", version_number: "" },
                          });
                        }}
                      />
                    </>
                  ) : (
                    <FormBootstrap.Control
                      type="file"
                      onChange={(e: any) => {
                        handleFile(e);
                      }}
                      accept=".bin"
                      data-testid="inputfile"
                      value="" // Permet d'appeler le onChange même si on reupload le meme fichier
                    ></FormBootstrap.Control>
                  )}
                </Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="version">
                <FormBootstrap.Label column sm="4">
                  Numéro de version
              </FormBootstrap.Label>
                <Col sm="8">{firmware?.version_number || ""}</Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="crc">
                <FormBootstrap.Label column sm="4">
                  CRC
              </FormBootstrap.Label>
                <Col sm="8">{firmware?.crc || ""}</Col>
              </FormBootstrap.Group>

              <FormBootstrap.Group as={Row} controlId="comment">
                <FormBootstrap.Label column sm="4">
                  Commentaire
              </FormBootstrap.Label>
                <Col sm="8">
                  <FormBootstrap.Control
                    as="textarea"
                    type="textarea"
                    rows="4"
                    {...getFieldProps("comment")}
                  />
                </Col>
              </FormBootstrap.Group>

              <Row>
                {auth.canAccess("IS_ACTIVE") && (
                  <Col sm="12">
                    <FormBootstrap.Group controlId={"is_active"}>
                      <div
                        className={
                          (touched.is_active || isSubmitting) && errors.is_active
                            ? "is-invalid"
                            : ""
                        }
                      >
                        <FormBootstrap.Label style={{ width: "160px" }}>
                          Etat:
                      </FormBootstrap.Label>

                        <FormBootstrap.Check
                          type="radio"
                          label="Actif"
                          inline
                          value="true"
                          onChange={(e: any) => {
                            setFieldValue("is_active", true);
                          }}
                          checked={values["is_active"] === true}
                          isInvalid={
                            (touched.is_active || isSubmitting) &&
                            typeof errors.is_active !== "undefined"
                          }
                          style={{ verticalAlign: "middle" }}
                        />
                        <FormBootstrap.Check
                          type="radio"
                          label="Inactif"
                          inline
                          {...getFieldProps("is_active")}
                          value="false"
                          onChange={(e: any) => {
                            setFieldValue("is_active", false);
                          }}
                          checked={values["is_active"] === false}
                          isInvalid={
                            (touched.is_active || isSubmitting) &&
                            typeof errors.is_active !== "undefined"
                          }
                          style={{ verticalAlign: "middle" }}
                        />
                      </div>
                      <FormBootstrap.Control.Feedback
                        type="invalid"
                        style={{ marginLeft: "160px", width: "auto" }}
                      >
                        {errors.is_active}
                      </FormBootstrap.Control.Feedback>
                    </FormBootstrap.Group>
                  </Col>
                )}
              </Row>

              <div className="text-center btns">
                <button
                  type="submit"
                  className="btn btn-large btn-primary"
                  disabled={
                    !firmware.version_number ||
                    !firmware.download_url ||
                    (!firmware.availability_date && !values.availability_date)
                  }
                >
                  Enregistrer
              </button>
              </div>
            </Form>
          )}
        </Formik>)}
    </div>
  );
};
