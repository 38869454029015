import React from "react";
import axios from "axios";
import ItemForm from "./ItemForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Cookies from "es-cookie";
import { Field } from "./FieldsInterface";
import { notificationService } from "./Notification";
import dataService from "./dataService";

interface Props extends RouteComponentProps<RouterProps> {
  action: string;
  endpoint: string;
  fields: Field[];
  name?: string;
  id?: string | number;
  transformItemOnSave?: any;
  transformItemOnLoad?: any;
  deleteMessage?: any;
}

type Message = {
  type:
    | "primary"
    | "success"
    | "secondary"
    | "danger"
    | "warning"
    | "info"
    | "dark"
    | "light"
    | undefined;
  show: boolean;
  title: string;
  content: string;
};

interface State {
  item: any;
  message: Message;
}

interface RouterProps {
  id: string;
}

class Item extends React.Component<Props, State> {
  public state: State = {
    item: null,
    message: {
      type: "primary",
      show: false,
      title: "",
      content: "",
    },
  };

  public submitInProgress = false;

  public getDefaultValues() {
    let item: any = {};
    this.props.fields?.forEach((field: Field, idx) => {
      switch (field.type) {
        case "text":
        case "file":
        case "password":
        case "textarea":
        case "url":
        case "richtext":
        case "number":
          item[field.name] = field.defaultValue || "";
          break;
        case "checkbox":
          item[field.name] = field.defaultValue || false;
          break;
        case "select":
          item[field.name] = null;
          break;
        case "select-multiple":
        case "autocomplete":
          item[field.name] = [];
          break;
        case "readonly":
          break;
        default:
          item[field.name] = field.defaultValue || null;
          break;
      }
    });
    return item;
  }

  public componentDidMount() {
    let defaultItem = this.getDefaultValues();

    this.setState({ item: defaultItem });

    if (this.props.match.params.id) {
      axios
        .get(this.props.endpoint + this.props.match.params.id + "/")
        .then((response) => {
          let data = response.data;
          data = Object.assign(defaultItem, data);
          if (typeof this.props.transformItemOnLoad === "function") {
            data = this.props.transformItemOnLoad(data);
          }

          this.setState({ item: data });
        });
    }
  }

  public async submitForm(item: any) {
    if (this.submitInProgress) {
      return;
    }

    let itemToSubmit = Object.assign({}, item);

    if (typeof this.props.transformItemOnSave === "function") {
      itemToSubmit = this.props.transformItemOnSave(itemToSubmit);
    }

    let editMode = this.props.match.params.id ? true : false;
    let url = this.props.endpoint;
    if (editMode) {
      url = url + this.props.match.params.id + "/";
    }
    let headers: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    let csrfToken = Cookies.get("csrftoken");
    if (csrfToken) {
      headers["X-CSRFToken"] = csrfToken;
    }

    this.submitInProgress = true;
    axios({
      url,
      method: editMode ? "PUT" : "POST",
      headers,
      data: itemToSubmit,
    })
      .then(
        (response) => {
          notificationService.show({
            title: "Succès",
            message: editMode
              ? "Enregistrement modifié avec succès"
              : "Enregistrement créé avec succès",
            type: "success",
          });

          // if data is store in dataservice, we refresh with the created/updated data
          let dataServiceData = dataService.dataToLoad.find(
            (dataConfig) => dataConfig.endpoint === this.props.endpoint
          );
          if (dataServiceData) {
            dataService.refreshData(dataServiceData.name).then(() => {
              setTimeout(() => {
                this.props.history.goBack();
              }, 1000);
            });
          } else {
            setTimeout(() => {
              this.props.history.goBack();
            }, 2000);
          }
        },
        (error) => {
          let statusCode = error.response.status;
          if (statusCode.toString().substring(0, 2) === "20") {
          } else if (statusCode.toString().substring(0, 1) === "5") {
            notificationService.show({
              title: "Erreur",
              message:
                "Une erreur serveur est survenue, merci de réessayer ultérieurement",
              type: "danger",
            });
          } else {
            let errorBody = error.response.data;
            let content =
              "Une erreur est survenue, merci de corriger les champs suivants:<br /><ul>";
            for (let i in errorBody) {
              content +=
                "<li>Champ `" + i + "` : " + errorBody[i].join(", ") + "</li>";
            }
            content += "</ul>";
            notificationService.show({
              title: "Erreur",
              message: content,
              type: "danger",
            });
          }
        }
      )
      .finally(() => {
        this.submitInProgress = false;
      });
  }

  public render() {
    return (
      <div className="block">
        {this.props.name && <h2>{this.props.name}</h2>}
        {(this.props.action === "add" && this.state.item) ||
        (this.props.action === "edit" &&
          this.state.item &&
          (this.state.item.id || this.state.item.sysid)) ? (
          this.props.fields !== undefined ? (
            <ItemForm
              fields={this.props.fields}
              item={this.state.item}
              submitForm={(item: any) => this.submitForm(item)}
            />
          ) : null
        ) : null}
      </div>
    );
  }
}

export default withRouter(Item);
