import React from "react";
import axios from "axios";
import { Route } from "react-router-dom";
import Login from "./components/Login";
import _ from "lodash";
import moment from "moment";
import dataService from "./components/Common/dataService";

interface User {
  name: string;
  email: string;
  previousLogin: string;
  photo: string;
  available_code_sections: string[];
}

export class Auth {
  public initialized = false;
  public isAuthenticated = false;
  public token: string | null = null;
  public user: User | null = null;

  public async getUserInfo() {
    await axios.get("/api/user_info/").then(
      (response) => {
        let data = response.data;
        this.user = {
          email: data.email,
          name: data.name,
          previousLogin: data.previous_login
            ? moment(data.previous_login).format("DD/MM/YYYY à HH:mm:ss")
            : data.last_login
            ? moment(data.last_login).format("DD/MM/YYYY à HH:mm:ss")
            : "-",
          photo: data.photo,
          available_code_sections: data.available_code_sections,
        };
        this.isAuthenticated = true;
        this.initialized = true;
      },
      () => {
        this.initialized = true;
      }
    );
  }

  public login = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "/api/login/password/",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: { email, password },
      }).then(
        (response) => {
          let data = response.data;
          this.user = {
            email: data.email,
            name: data.name,
            previousLogin: data.previous_login
              ? moment(data.previous_login).format("DD/MM/YYYY à HH:mm:ss")
              : "-",
            photo: data.photo,
            available_code_sections: data.available_code_sections,
          };
          this.isAuthenticated = true;
          resolve();
        },
        (error) => {
          reject(error.response.data);
        }
      );
    });
  };
  public login_sso() {
    axios.get("/api/get_auth_url/").then((response) => {
      let data = response.data;
      window.location = _.get(data, "auth_url");
    });
  }

  public logout = () => {
    axios.post("/api/logout/").then((response) => {
      this.isAuthenticated = false;
      this.user = null;
      window.location.pathname = "/";
      window.location.reload();
    });
  };

  public canAccess = (section: string) => {
    return this.user?.available_code_sections.includes(section);
  };
}

export let auth = new Auth();

type Props = {
  component?: any;
  redirect?: any;
  path?: any;
};
type State = {
  authInitialized: boolean;
  dataAreLoaded: boolean;
};

export class LoggedInRoute extends React.Component<Props, State> {
  public state: State = {
    authInitialized: false,
    dataAreLoaded: false,
  };

  public async componentDidMount() {
    if (!auth.initialized) {
      await auth.getUserInfo();
      this.setState({ authInitialized: auth.initialized });
      if (auth.isAuthenticated) {
        await dataService.loadData();
        this.setState({ dataAreLoaded: true });
      }
    }
  }

  public render() {
    const { component: Component, redirect: pathname, ...rest } = this.props;
    return auth.initialized ? (
      <Route
        {...rest}
        render={(props) =>
          auth.isAuthenticated ? (
            this.state.dataAreLoaded ? (
              <Component {...props} />
            ) : (
              <></>
            )
          ) : (
            <Login />
          )
        }
      />
    ) : (
      <></>
    );
  }
}
