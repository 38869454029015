import React from "react";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import { Trans } from "react-i18next";
import { auth } from "auth";
import { faEnvelope, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Login.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface CallBackProps {}

interface CallBackState {
  error: string | null;
}

export class Callback extends React.Component<CallBackProps, CallBackState> {
  public state: CallBackState = {
    error: null,
  };

  public componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("code")) {
      let code = urlParams.get("code");
      axios
        .post("/api/login/sso/", { code })
        .then(() => {
          window.location.search = "";
          window.location.pathname = "/";
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  }

  public render() {
    return (
      <div>
        {!this.state.error ? (
          <Trans>Redirection en cours...</Trans>
        ) : (
          <Trans>{this.state.error}</Trans>
        )}
      </div>
    );
  }
}

interface Props {}

interface State {
  error: string | null;
  email: string;
  password: string;
}

class Login extends React.Component<RouteComponentProps<{}>> {
  public state: State = {
    error: null,
    email: "",
    password: "",
  };

  public componentDidMount() {
    if (window.location.pathname !== "/login") {
      this.props.history.push("/login");
    }
    document.body.style.backgroundImage = "url('/bg.jpg')";
  }

  public handleEmailChange(e: any) {
    this.setState({ email: e.target.value });
  }
  public handlePasswordChange(e: any) {
    this.setState({ password: e.target.value });
  }

  public handleSubmit(event: any) {
    event.preventDefault();

    auth.login(this.state.email, this.state.password).then(
      () => {
        window.location.search = "";
        window.location.pathname = "/";
      },
      (error: string) => {
        this.setState({ error });
      }
    );
  }

  public render() {
    return (
      <Container className="align-text-bottom mt-5 pt-2">
        <Row className="row justify-content-center mx-auto">
          <Col sm={12} md={6} lg={12} xs={1} className="text-center mb-5">
            <img
              src="/btoolzup-logo-original.png"
              className="img-fluid"
              alt="Birdz"
            />
          </Col>

          <Col sm={12} md={6} lg={12} xs={1} className="text-center">
            <h2>Connexion</h2>
          </Col>

          <Col sm={12} md={6} lg={12} xs={1}>
            <Form className="form" onSubmit={(e: any) => this.handleSubmit(e)}>
              <Row>
                <Col sm={12} md={4} className="pt-3 pl-3">
                  <Form.Label>Adresse e-mail</Form.Label>
                </Col>

                <Col sm={12} md={8} className="pr-3 pt-3">
                  <Form.Group controlId="email">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="email"
                        placeholder=""
                        onChange={(e: any) => this.handleEmailChange(e)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={4} className="pl-3">
                  <Form.Label>Mot de passe</Form.Label>
                </Col>

                <Col sm={12} md={8} className="pr-3">
                  <Form.Group controlId="password">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faAsterisk} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="password"
                        placeholder=""
                        onChange={(e: any) => this.handlePasswordChange(e)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              {this.state.error && (
                <Row>
                  <Col lg={12}>
                    <Alert variant="danger">
                      <Form.Text data-testid="error-message">
                        {this.state.error}
                      </Form.Text>
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row className="pt-1 pb-3">
                <Col>
                  <Button
                    className="btn btn-outline-light btn-primary"
                    block
                    type="submit"
                    data-testid="login-button"
                  >
                    <Trans>Connexion</Trans>
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button onClick={auth.login_sso} block variant="secondary">
                    <Trans>Google</Trans>
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2 mb-2">
                <Col className="text-center">Mot de passe oublié</Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Login);
