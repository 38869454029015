import React from "react";
import Sidebar from "./Sidebar";
import { Breadcrumb, Navbar } from "react-bootstrap";
import { Route, Switch, NavLink } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { auth } from "../auth";
import Home from "./Home";
import Users from "./Admin/Users";
import Groups from "./Admin/Groups";
import Sections from "./Admin/Sections";
import Messages from "./Admin/Messages";
import Logs from "./Admin/Logs";
import { confirmService, Confirm } from "./Common/Confirm";
import { notificationService, Notification } from "./Common/Notification";
import { Firmwares } from "./Firmwares/Firmwares";
import { FirmwareForm } from "./Firmwares/FirmwareForm";
import { DeviceTypes } from "./Admin/DeviceTypes";
import { Companies } from "./Admin/Companies";
import { Statistics } from "./Statistics/Statistics";
import { Sends } from "./Sends/Sends";
import { SendForm } from "./Sends/SendForm";
import { FirmwareOTAHistoric } from "./FirmwareOTAHistoric/FirmwareOTAHistoric";
import { AppUsers } from "./FirmwareOTAHistoric/AppUsers";
import { AppUserForm } from "./FirmwareOTAHistoric/AppUserForm";
import { AppDevices } from "./FirmwareOTAHistoric/AppDevices";
import { FirmwareLog } from "./FirmwareOTAHistoric/FirmwareLog";

interface Props {}

interface State {}

const routes = [
  { path: "/", breadcrumb: "Accueil" },
  { path: "/admin", breadcrumb: "Administration" },
  { path: "/admin/users", breadcrumb: "Utilisateurs" },
  { path: "/admin/groups", breadcrumb: "Groupes" },
  { path: "/admin/sections", breadcrumb: "Sections" },
  { path: "/admin/customers", breadcrumb: "Clients" },
  { path: "/admin/contracts", breadcrumb: "Contrats" },
  { path: "/admin/operators", breadcrumb: "Opérateurs" },
  { path: "/admin/messages", breadcrumb: "Messages" },
  { path: "/admin/logs", breadcrumb: "Logs" },
  { path: "/admin/device-types", breadcrumb: "Device Types" },
  { path: "/statistics", breadcrumb: "Statistiques" },
  { path: "/sends", breadcrumb: "Envois" },
  { path: "/firmwares-ota-historic", breadcrumb: "Parc BToolz" },
  { path: "/firmwares-ota-historic/users", breadcrumb: "Utilisateurs" },
  { path: "/firmwares-ota-historic/devices", breadcrumb: "Devices" },
  { path: "/firmwares-ota-historic/logs", breadcrumb: "Logs" },
];

const Breadcrumbs = withBreadcrumbs(routes)(({ breadcrumbs }) => {
  return (
    <Breadcrumb>
      {breadcrumbs.map(({ breadcrumb, match }, key) => {
        let existInRoutes = routes.find((route) => route.path === match.url);
        if (existInRoutes) {
          return (
            <span key={match.url}>
              <NavLink to={match.url}>{breadcrumb}</NavLink>
              {key + 1 !== breadcrumbs.length && (
                <span style={{ margin: "0px 5px 0px 5px" }}> / </span>
              )}
            </span>
          );
        } else {
          return null;
        }
      })}
    </Breadcrumb>
  );
});

class DashboardLayout extends React.Component<Props, State> {
  public state: State = {};

  public render() {
    return (
      <div className="wrapper">
        <Confirm
          ref={(component: any) => {
            confirmService.instance = component;
          }}
        />
        <Notification
          ref={(component: any) => {
            notificationService.instance = component;
          }}
        />
        <Sidebar {...this.props} />
        <div className="main-panel">
          <Navbar bg="light" expand="lg">
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                {auth.user?.photo && (
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: 'url("' + auth.user.photo + '"',
                    }}
                  />
                )}
                {auth.user ? auth.user.name : ""}
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>

          {auth.user?.previousLogin && (
            <span className="lastLogin">
              Dernière connexion le {auth.user.previousLogin}
            </span>
          )}

          <Breadcrumbs />

          <div className="page-content">
            <Switch>
              {/* {auth.canAccess("ADMIN_MENU") && [ */}
              <Route path="/admin/users/add" key="user-add">
                <Users action="add" />
              </Route>
              ,
              <Route path="/admin/users/edit/:id" key="user-edit">
                <Users action="edit" />
              </Route>
              ,
              <Route path="/admin/users" key="user-list">
                <Users action="list" />
              </Route>
              ,
              <Route path="/admin/groups/add" key="group-add">
                <Groups action="add" />
              </Route>
              ,
              <Route path="/admin/groups/edit/:id" key="group-edit">
                <Groups action="edit" />
              </Route>
              ,
              <Route path="/admin/groups" key="group-list">
                <Groups action="list" />
              </Route>
              ,
              <Route path="/admin/sections/add" key="section-add">
                <Sections action="add" />
              </Route>
              ,
              <Route path="/admin/sections/edit/:id" key="section-edit">
                <Sections action="edit" />
              </Route>
              ,
              <Route path="/admin/sections" key="section-list">
                <Sections action="list" />
              </Route>
              ,
              <Route path="/admin/logs/details/:id" key="log-detail">
                <Logs action="details" />
              </Route>
              ,
              <Route path="/admin/logs" key="log-list">
                <Logs action="list" />
              </Route>
              ,
              <Route path="/admin/messages/add" key="messages-add">
                <Messages action="add" />
              </Route>
              ,
              <Route path="/admin/messages/edit/:id" key="messages-edit">
                <Messages action="edit" />
              </Route>
              ,
              <Route path="/admin/messages" key="messages-list">
                <Messages action="list" />
              </Route>
              ,
              <Route path="/admin/device-types/add" key="device-types-add">
                <DeviceTypes action="add" />
              </Route>
              ,
              <Route
                path="/admin/device-types/edit/:id"
                key="device-types-edit"
              >
                <DeviceTypes action="edit" />
              </Route>
              ,
              <Route path="/admin/device-types" key="device-types-list">
                <DeviceTypes action="list" />
              </Route>
              ,
              <Route path="/admin/companies/add" key="companies-add">
                <Companies action="add" />
              </Route>
              ,
              <Route path="/admin/companies/edit/:id" key="companies-edit">
                <Companies action="edit" />
              </Route>
              ,
              <Route path="/admin/companies" key="companies-list">
                <Companies action="list" />
              </Route>
              ,{/* ]} */}
              <Route path="/firmwares/add" key="firmwares-add">
                <FirmwareForm action="add" />
              </Route>
              ,
              <Route path="/firmwares/edit/:id" key="firmwares-edit">
                <FirmwareForm action="edit" />
              </Route>
              ,
              <Route path="/firmwares" key="firmwares-list">
                <Firmwares action="list" />
              </Route>
              ,
              <Route path="/statistics" key="statistics">
                <Statistics />
              </Route>
              ,
              <Route path="/sends/new" key="new-sends">
                <SendForm action="add" />
              </Route>
              ,
              <Route path="/sends/view/:id" key="view-sends">
                <SendForm action="view" />
              </Route>
              ,
              <Route path="/sends" key="sends">
                <Sends />
              </Route>
              ,
              <Route
                path="/firmwares-ota-historic/users/edit/:id"
                key="app-users-edit"
              >
                <AppUserForm action="edit" />
              </Route>
              ,
              <Route path="/firmwares-ota-historic/users" key="app-users">
                <AppUsers />
              </Route>
              ,
              <Route
                path="/firmwares-ota-historic/devices/view/:id"
                key="app-devices-view"
              >
                <AppDevices action="view" />
              </Route>
              ,
              <Route
                path="/firmwares-ota-historic/logs/:id"
                key="app-users-edit"
              >
                <FirmwareLog />
              </Route>
              ,
              <Route path="/firmwares-ota-historic/devices" key="app-devices">
                <AppDevices action="list" />
              </Route>
              <Route
                path="/firmwares-ota-historic"
                key="firmwares-ota-historic"
              >
                <FirmwareOTAHistoric />
              </Route>
              {/* DEFAULT ROUTE */}
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardLayout;
