import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import fr from "date-fns/locale/fr";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import axios from "axios";
import Item from "../Common/Item";
import * as Yup from "yup";
import ListPage from "../Common/ListPage";
import dataService from "components/Common/dataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface ParamTypes {
  id: string;
}

export const AppUserForm = (params: any) => {
  const { id } = useParams<ParamTypes>();
  const endpoint = "/api/app-users/";
  const history = useHistory();

  const transformItemOnSave = (item: any) => {
    console.log("item", item);
    item.society = item?.society?.id;

    if (item.password === "") {
      delete item.password;
    }
    return item;
  };

  const transformItemOnLoad = (item: any) => {
    if (item.society) {
      item.society = dataService.getData("societies").find((society: any) => {
        return society.id === item.society;
      });
    }
    return item;
  };
  const formFields = [
    {
      name: "name",
      type: "text",
      label: "Nom",
      validation: Yup.string().required("Vous devez renseigner le nom"),
    },
    {
      name: "society",
      type: "autocomplete",
      label: "Société",
      options: {
        source: "/api/societies/",
        searchIsMulti: false,
      },
    },
    {
      name: "email",
      type: "text",
      label: "Email",
      validation: Yup.string()
        .email("Vous devez renseigner un e-mail valide")
        .required("L'email ne peut être vide"),
    },
    {
      name: "password",
      type: "password",
      label: "Password",
    },
    {
      name: "date_joined",
      type: "readonly",
      label: "Date inscription",
      transform: (value: any) => {
        let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
    {
      name: "last_app_version",
      type: "readonly",
      label: "Dernière version ",
      transform: (value: any) => {
        return value || "-";
      },
    },
    {
      name: "devices",
      type: "readonly",
      label: "Devices rattachés",
      transform: (user: any) => {
        return (
          <ListPage
            endpoint={"/api/user-devices/?user=" + id + "&"}
            fields={[
              {
                name: "device_type",
                label: "Type device",
              },
              {
                name: "serial_number",
                label: "Numéro de série",
              },
              {
                name: "last_firmware_version",
                label: "Dernier firmware connu",
              },
              {
                name: "last_update_date",
                label: "Dernière date de MAJ",
                transform: (value: any) => {
                  let date =
                    value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
                  return date || "";
                },
              },
            ]}
            name=""
            actions={[
              {
                name: "view",
                label: "Voir le device",
                icon: <FontAwesomeIcon icon={faMobileAlt} />,
                url: "/firmwares-ota-historic/devices/view/:id",
              },
              {
                name: "log",
                label: "Logs",
                icon: <FontAwesomeIcon icon={faExchangeAlt} />,
                onClick: (value: any) => {
                  history.push(
                    "/firmwares-ota-historic/logs/" +
                      value.last_update__id +
                      "/"
                  );
                },
                isVisible: (value: any) => {
                  return value.last_update__id;
                },
              },
            ]}
          />
        );
      },
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, name: "Actif" },
          { value: false, name: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
  ];
  useEffect(() => {
    if (params.action === "edit" && id) {
      axios.get(endpoint + id + "/").then((response) => {});
    }
  }, [params, id]);

  return (
    <Item
      action="edit"
      endpoint={endpoint}
      fields={formFields}
      name="Fiche utilisateur application"
      transformItemOnSave={(item: any) => transformItemOnSave(item)}
      transformItemOnLoad={(item: any) => transformItemOnLoad(item)}
    />
  );
};
