import React, { useState } from "react";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Modal, Row, Col } from "react-bootstrap";

interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  action: "list" | "details";
}

type Log = {
  id: number;
  date: any;
  ip: string;
  message: string;
  type: number;
  type_label: string;
  user_agent: string;
  user__name: string;
};

type LogsDetailProps = {
  logItem: Log;
  onClose(): void;
};

interface State {
  logDetail: Log | null;
}

const endpoint = "/api/user-logs/";

class Logs extends React.Component<Props, State> {
  public state: State = {
    logDetail: null,
  };

  public render() {
    let listFields = [
      { name: "id", label: "#", options: { width: "30px" } },
      {
        name: "date",
        label: "Date",
        orderable: true,
        transform: (value: any) => {
          return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
        },
        options: { width: "150px" },
      },
      {
        name: "user__name",
        label: "Utilisateur",
        className: "text-center",
        options: { width: "150px" },
        orderable: true,
      },
      {
        name: "type_label",
        label: "Type",
        className: "text-center",
        options: { width: "150px" },
      },
      {
        name: "message",
        label: "Message",
        className: "text-center",
      },
    ];

    let searchFields = [
      {
        name: "user",
        label: "Utilisateur",
        options: {
          identifier: "id",
          source: "/api/users/",
          label: "name",
          isMulti: false,
        },
        className: "col-sm-6",
      },
      {
        name: "type",
        label: "Type",
        type: "select-multiple",
        options: {
          values: [
            { value: 1, label: "Connexion" },
            { value: 2, label: "INFO" },
            { value: 3, label: "WARNING" },
            { value: 4, label: "ERROR" },
          ],
        },
        className: "col-sm-6",
      },
      {
        name: "date_after",
        label: "Entre le ",
        type: "datepicker",
        className: "col-sm-6",
      },
      {
        name: "date_before",
        label: "et le",
        type: "datepicker",
        className: "col-sm-6",
      },
    ];

    let actions = [
      {
        name: "detail",
        label: "Détail",
        icon: <FontAwesomeIcon icon={faSearch} />,
        onClick: (logDetail: Log) => {
          this.setState({
            logDetail,
          });
        },
      },
    ];

    return (
      <div>
        {this.props.action === "list" && (
          <span>
            <h2>
              <Trans>Liste des logs</Trans>
            </h2>
            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
            />
          </span>
        )}
        {this.state.logDetail && (
          <LogsDetail
            logItem={this.state.logDetail}
            onClose={() => {
              this.setState({ logDetail: null });
            }}
          />
        )}
      </div>
    );
  }
}

type DetailField = {
  label: string;
  name: keyof Log;
};

export const LogsDetail = ({ logItem, onClose }: LogsDetailProps) => {
  const [show, setShow] = useState(true);

  const detailFields: DetailField[] = [
    { label: "Id", name: "id" },
    { label: "Date", name: "date" },
    { label: "Utilisateur", name: "user__name" },
    { label: "Type d'action", name: "type_label" },
    { label: "Message", name: "message" },
    { label: "Adresse IP", name: "ip" },
    { label: "Informations complémentaires", name: "user_agent" },
  ];

  return (
    <>
      <Modal
        show={show}
        animation={false}
        onHide={() => {
          setShow(false);
          onClose();
        }}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Détail log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailFields.map((field: DetailField) => {
            return (
              <Row className="pb-4" key={field.name}>
                <Col sm="4">{field.label}</Col>
                <Col sm="8">{logItem[field.name]}</Col>
              </Row>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(Logs);
