import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MessageList from "./MessageList";

interface RouterProps {
  id: string;
}

interface State {}

interface Props extends RouteComponentProps<RouterProps> {}

class Home extends React.Component<Props, State> {
  public state: State = {};

  public render() {
    return <MessageList />;
  }
}

export default withRouter(Home);
