import axios from "axios";

type Data = {
  [key: string]: any;
};

class DataService {
  public dataToLoad = [
    {
      name: "groups",
      endpoint: "/api/groups/",
    },
    {
      name: "sections",
      endpoint: "/api/sections/",
    },
    {
      name: "messageTypes",
      endpoint: "/api/message-types/",
    },
    {
      name: "deviceTypes",
      endpoint: "/api/device-types/",
    },
    {
      name: "societies",
      endpoint: "/api/societies/",
    },
  ];

  public data: Data = {};

  public async loadData() {
    let allPromises: any = [];

    this.dataToLoad.forEach((data) => {
      allPromises.push(axios.get(data.endpoint));
    });

    return Promise.all(allPromises).then(
      (allData) => {
        allData.forEach((response: any, dataIndex) => {
          this.data[this.dataToLoad[dataIndex].name] =
            response.data.results || response.data;
        });
      },
      (error) => {
        console.error(
          "L'endpoint " + error.response.config.url + " n'est pas joignable"
        );
      }
    );
  }

  public getData(dataName: string, filterCriteria: any = null) {
    let data = (this.data && this.data[dataName]) || [];
    if (filterCriteria) {
      let filterName = Object.keys(filterCriteria)[0];
      let filterValue = filterCriteria[filterName];
      data = data.filter((item: any) => item[filterName] === filterValue);
    }

    return data;
  }

  public dataStoreInDataService(dataName: string) {
    return this.dataToLoad.find((data) => data.name === dataName);
  }

  public refreshData(dataName: string) {
    return new Promise((resolve, reject) => {
      let dataToLoadConfig = this.dataStoreInDataService(dataName);
      if (dataToLoadConfig) {
        axios.get(dataToLoadConfig.endpoint).then(
          (response) => {
            this.data[dataName] = response.data.results || response.data;
            resolve({});
          },
          () => {
            reject();
          }
        );
      } else {
        reject();
      }
    });
  }
}

let dataService = new DataService();
export default dataService;
