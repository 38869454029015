import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import "./StatPieChart.scss";

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  displayMode,
  value,
  legends,
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={legends[Object.keys(legends)[index]].color}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {displayMode === "values" ? `${value}` : `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type PieChartProps = {
  data: Array<{ name: string; value: number }>;
  title?: string;
  displayMode?: "values" | "percent";
  legends: any;
};
export const StatPieChart = ({
  data,
  title,
  displayMode,
  legends,
}: PieChartProps) => {
  return data ? (
    <div>
      <div className="text-center">
        {title && <h3>{title}</h3>}
        <div className="legends">
          {legends &&
            Object.keys(legends).map((legendIndex: string) => {
              let legend = legends[legendIndex];
              return (
                <div key={legend.label}>
                  <span
                    style={{
                      backgroundColor: legend.bgColor,
                    }}
                  ></span>{" "}
                  {legend.label}
                </div>
              );
            })}
        </div>
      </div>
      <div style={{ margin: "auto", width: "250px" }}>
        <PieChart width={250} height={250}>
          <Pie
            data={data}
            labelLine={false}
            label={<CustomLabel displayMode={displayMode} legends={legends} />}
            dataKey="value"
          >
            {data.map((entry: any, index: number) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={legends && legends[index]?.bgColor}
                />
              );
            })}
          </Pie>
        </PieChart>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default StatPieChart;
