import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { auth } from "auth";
import * as Cookies from "es-cookie";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://785c4ca34aab48d7a52c20305a398be7@sentry.birdz.com/3",
  });
}

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  async function (error) {
    // Do something with response error
    if (
      error.response.status === 403 &&
      document.location.pathname !== "/login"
    ) {
      await auth.logout();
      document.location.href = "/login";
    }

    // return error;
    // Trow errr again (may be need for some other catch)
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";

  let csrfToken = Cookies.get("csrftoken");
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }

  return config;
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
