import React, { useEffect } from "react";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faMobileAlt,
  faPortrait,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

interface ParamTypes {
  id: string;
}

export const AppUsers = (params: any) => {
  const endpoint = "/api/app-users/";
  const history = useHistory();

  useEffect(() => {});

  let listFields = [
    {
      name: "name",
      label: "Nom",
      transform: (value: any, object: any) => {
        return (
          <Link to={"/firmwares-ota-historic/users/edit/" + object.id}>
            {value}
          </Link>
        );
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "device_count",
      label: "Nombre devices",
    },
    {
      name: "date_joined",
      label: "Date d'inscription",
      className: "text-center",
      transform: (value: any) => {
        let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
  ];

  let searchFields = [
    {
      name: "name",
      label: "Nom",
      options: {
        identifier: "id",
        source: "/api/app-users/",
        label: "name",
        isMulti: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        identifier: "email",
        source: "/api/app-users/",
        label: "email",
        isMulti: false,
      },
    },
    {
      name: "devices",
      label: "Devices",
      options: {
        identifier: "id",
        source: "/api/user-devices/",
        label: "id",
      },
    },
  ];

  let actions = [
    {
      name: "edit",
      label: "Modifier l'utilisateur",
      icon: <FontAwesomeIcon icon={faPortrait} />,
      url: "/firmwares-ota-historic/users/edit/:id",
    },
    {
      name: "device",
      label: "Devices",
      icon: <FontAwesomeIcon icon={faMobileAlt} />,
      onClick: (value: any) => {
        history.push("/firmwares-ota-historic/devices?user=" + value.id);
      },
    },
    {
      name: "log",
      label: "Logs",
      icon: <FontAwesomeIcon icon={faExchangeAlt} />,
      onClick: (value: any) => {
        history.push(
          "/firmwares-ota-historic/logs/" + value.last_update__id + "/"
        );
      },
      isVisible: (value: any) => {
        return value.last_update__id;
      },
    },
  ];

  return (
    <div>
      <div className="block">
        <h2>
          <Trans>Liste des utilisateurs</Trans>
        </h2>

        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
        />
      </div>
    </div>
  );
};
