import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AutocompleteField from "./AutocompleteField";
import Select from "react-select";
import { Field } from "./FieldsInterface";
import { faEdit, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JoditEditor from "jodit-react";
import { confirmService } from "./Confirm";
import { Formik } from "formik";
import * as Yup from "yup";
import { auth } from "auth";

type AutocompleteItem = {
  label: string;
  value: string | number;
};

interface Props {
  submitForm: any;
  item: any;
  fields: Array<Field>;
}

interface State {
  item: any;
  editorConfig: any;
}

class ItemForm extends React.Component<Props, State> {
  public state: State = {
    item: null,
    editorConfig: { readonly: false },
  };

  public initialState = {};

  public componentDidMount() {
    this.setState({ item: this.props.item });
    this.initialState = Object.assign({}, this.props.item);
  }

  private toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  public async handleFile(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let selectedFile = e.target.files?.length ? e.target.files[0] : null;
    if (selectedFile) {
      let file = await this.toBase64(selectedFile);
      this.updateItem(fieldName, file);
    }
  }

  public deleteFile(fieldName: string) {
    this.updateItem(fieldName, "");
  }

  public handleRadio(
    fieldName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let field = this.getFieldByName(fieldName);
    let chosenOption;
    if (field.options?.values) {
      chosenOption = field.options.values.find((option) => {
        return option.name === e.target.value;
      });
    }
    if (chosenOption) {
      this.updateItem(fieldName, chosenOption.value);
    }
  }

  public getFieldByName(fieldName: string) {
    return this.props.fields.filter(
      (field: Field) => field.name === fieldName
    )[0];
  }

  public getIdentifierField(fieldName: string) {
    let field = this.getFieldByName(fieldName);
    return field.options?.identifier || "id";
  }

  public getLabelField(fieldName: string) {
    let field = this.getFieldByName(fieldName);
    return field.options?.label || "name";
  }

  public handleAutocompleteSelect(fieldName: string, valueSelect: any) {
    let identifier = this.getIdentifierField(fieldName);
    let label = this.getLabelField(fieldName);

    let itemValue: any;

    if (valueSelect) {
      if (typeof valueSelect === "object" && valueSelect instanceof Array) {
        itemValue = [];
        valueSelect.forEach((value: AutocompleteItem) => {
          itemValue.push({
            [identifier]: value.value,
            [label]: value.label,
          });
        });
      } else {
        itemValue = {
          [identifier]: valueSelect.value,
          [label]: valueSelect.label,
        };
      }
    }
    this.updateItem(fieldName, itemValue);
  }

  public handleSelect(
    fieldName: string,
    valueSelect: AutocompleteItem | AutocompleteItem[]
  ) {
    let field = this.getFieldByName(fieldName);
    let itemValue: any;

    if (!field.options?.searchIsMulti && !Array.isArray(valueSelect)) {
      itemValue = valueSelect?.value || [];
    } else {
      itemValue = [];
      if (valueSelect && Array.isArray(valueSelect)) {
        valueSelect.forEach((value: AutocompleteItem) => {
          itemValue.push(value.value);
        });
      }
    }
    this.updateItem(fieldName, itemValue);
  }

  public removeAutocompleteField(fieldName: string, index: number) {
    this.updateItem(fieldName, this.form.values[fieldName].splice(index, 1));
  }

  public updateItem(fieldName: string, fieldValue: any) {
    this.form.setFieldValue(fieldName, fieldValue);
    let field = this.getFieldByName(fieldName);

    if (typeof field.onUpdate === "function") {
      field.onUpdate(fieldValue, this.form.values, (item: any) => {
        for (let itemKey in item) {
          this.form.setFieldValue(itemKey, item[itemKey]);
        }
      });
    }
  }

  public checkPermission(field: Field) {
    if (field?.permissions) {
      if (field.permissions instanceof Array) {
        let canAccess = true;
        field.permissions.forEach((permission: string) => {
          if (!auth.canAccess(permission)) {
            canAccess = false;
          }
        });
        return canAccess;
      } else {
        return auth.canAccess(field.permissions);
      }
    } else {
      return true;
    }
  }

  public renderFormField(field: Field) {
    let identifierField = this.getIdentifierField(field.name);
    let labelField = this.getLabelField(field.name);

    let value = this.form.values[field.name];
    if (typeof field.transform === "function") {
      value = field.transform(value);
    }

    if (!this.checkPermission(field)) {
      return <React.Fragment key={field.name}></React.Fragment>;
    }

    return (field["type"] === "readonly" && value) ||
      field["type"] !== "readonly" ? (
      <div key={field.name} className={field?.className || "col-sm-12"}>
        <Form.Group as={Row} controlId={"formControl" + field.name}>
          <Form.Label column sm={field?.labelSize || "2"}>
            {field.label}
          </Form.Label>
          <Col sm={field?.fieldSize || "10"}>
            {(() => {
              switch (field["type"]) {
                case "text":
                case "password":
                case "url":
                case "number":
                  return (
                    <BasicField
                      name={field.name}
                      type={field.type}
                      value={value}
                      onChange={(e: any) =>
                        this.updateItem(field.name, e.target.value)
                      }
                      errors={this.form.errors}
                    />
                  );
                case "file":
                  return (
                    <FileField
                      value={value}
                      onChange={(e: any) => this.handleFile(field.name, e)}
                      onDelete={() => this.deleteFile(field.name)}
                    />
                  );

                case "checkbox":
                  return (
                    <CheckboxField
                      value={value}
                      onChange={(e: any) =>
                        this.updateItem(field.name, e.target.checked)
                      }
                    />
                  );
                case "radio":
                  return (
                    <RadioField
                      options={field.options?.values}
                      value={value}
                      onChange={(e: any) => this.handleRadio(field.name, e)}
                      name={field.name}
                      errors={this.form.errors}
                    />
                  );
                case "textarea":
                  return (
                    <>
                      <BasicField
                        type={field.type}
                        value={value}
                        as="textarea"
                        rows="3"
                        onChange={(e: any) =>
                          this.updateItem(field.name, e.target.value)
                        }
                        name={field.name}
                        errors={this.form.errors}
                      />
                    </>
                  );
                case "select-multiple":
                case "select":
                  let possibleValues =
                    (field.options?.values &&
                      field.options?.values.map((option: any) => {
                        return {
                          label: option[labelField],
                          value: option[identifierField],
                        };
                      })) ||
                    [];

                  return (
                    <SelectField
                      name={field.name}
                      options={possibleValues}
                      isMulti={
                        field.options?.searchIsMulti ||
                        field["type"] === "select-multiple"
                          ? true
                          : false
                      }
                      onChange={(values: any) =>
                        this.handleSelect(field.name, values)
                      }
                      value={value}
                      errors={this.form.errors}
                    />
                  );
                case "autocomplete":
                  if (value) {
                    if (typeof value === "object" && value instanceof Array) {
                      value =
                        value &&
                        value.length > 0 &&
                        value.map((val: any) => {
                          return {
                            label: val[labelField],
                            value: val[identifierField],
                          };
                        });
                    } else {
                      value = {
                        label: value[labelField],
                        value: value[identifierField],
                      };
                    }
                  }

                  // If autocomplete is dependent of other value, we create "getter" to get theses other values
                  if (
                    field.options?.relatedTo?.field &&
                    this.form.values[field.options.relatedTo.field]
                  ) {
                    field.options.relatedTo.getValues = () => {
                      return (
                        this.form.values[field.options?.relatedTo.field] &&
                        this.form.values[field.options?.relatedTo.field].map(
                          (value: any) =>
                            value[
                              field.options?.identifier
                                ? field.options?.identifier
                                : "id"
                            ]
                        )
                      );
                    };
                  }

                  return (
                    <AutocompleteField
                      field={field}
                      onSelect={(values) =>
                        this.handleAutocompleteSelect(field.name, values)
                      }
                      value={value}
                    />
                  );
                case "readonly":
                  return value;
                case "richtext":
                  return (
                    <>
                      <div
                        className={
                          this.form.errors[field.name]
                            ? "select-container is-invalid"
                            : "select-container"
                        }
                      >
                        <JoditEditor
                          value={value}
                          config={this.state.editorConfig}
                          onChange={(content: any) =>
                            this.updateItem(field.name, content)
                          }
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {this.form.errors[field.name]}
                      </Form.Control.Feedback>
                    </>
                  );
              }
            })()}
          </Col>
        </Form.Group>
      </div>
    ) : (
      <React.Fragment key={field.name} />
    );
  }

  public resetForm() {
    confirmService.show({
      title: "Souhaitez-vous vraiment annuler vos saisies?",
      onValidate: () => {
        this.setState({ item: Object.assign({}, this.initialState) });
      },
    });
  }

  public form: any = {
    errors: {},
  };

  public render() {
    let validationSchema: any = {};
    this.props.fields
      .filter((field: Field) => {
        return typeof field.validation !== "undefined";
      })
      .map((field: Field) => {
        return { name: field.name, validation: field.validation };
      })
      .forEach((item: any) => (validationSchema[item.name] = item.validation));

    return (
      this.state.item && (
        <Formik
          initialValues={this.props.item}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            this.props.submitForm(values);
          }}
          validationSchema={Yup.object().shape(validationSchema)}
        >
          {({ values, handleSubmit, setFieldValue, errors, setErrors }) => {
            this.form = {
              values,
              setFieldValue,
              errors,
              setErrors,
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  {this.props.fields.map((field: Field) => {
                    return this.renderFormField(field);
                  })}
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button
                      type="submit"
                      disabled={
                        this.form.errors &&
                        Object.keys(this.form.errors).length > 0
                      }
                    >
                      {this.state.item.id
                        ? [
                            <FontAwesomeIcon key="edit" icon={faEdit} />,
                            " Modifier",
                          ]
                        : [
                            <FontAwesomeIcon key="add" icon={faPlus} />,
                            " Ajouter",
                          ]}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      )
    );
  }
}

export default ItemForm;

const BasicField = ({ name, type, value, onChange, errors, ...props }: any) => {
  return (
    <>
      <Form.Control
        type={type}
        onChange={(e: any) => onChange(e)}
        value={value || ""}
        isInvalid={errors && typeof errors[name] !== "undefined"}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        {errors && errors[name]}
      </Form.Control.Feedback>
    </>
  );
};

const CheckboxField = ({ value, onChange }: any) => {
  return (
    <Form.Check
      type="checkbox"
      label=""
      checked={value}
      onChange={(e: any) => onChange(e)}
    />
  );
};

const FileField = ({ value, onDelete, onChange }: any) => {
  return (
    <span>
      {value && (
        <img
          src={value}
          width="200px"
          onClick={() => onDelete()}
          alt="User avatar"
        />
      )}
      <div className="fileInput">
        <Button variant="secondary">
          <FontAwesomeIcon icon={faUpload} /> Choisir un fichier
        </Button>
        <Form.Control type="file" onChange={(e: any) => onChange(e)} />
      </div>
    </span>
  );
};

const RadioField = ({ options, name, value, onChange, errors }: any) => {
  return (
    <>
      <div className={errors[name] ? "is-invalid" : ""}>
        {options &&
          options.map((optionValue: any, optionValueIdx: number) => {
            return (
              <Form.Check
                key={optionValueIdx}
                name={name}
                id={name + optionValue.name}
                value={optionValue.name}
                type="radio"
                label={optionValue.name}
                checked={optionValue.value === value}
                inline
                onChange={(e: any) => onChange(e)}
              />
            );
          })}
      </div>
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </>
  );
};

const SelectField = ({
  name,
  options,
  value,
  onChange,
  errors,
  ...props
}: any) => {
  let val = [];

  if (Array.isArray(value)) {
    value.forEach((item: any) => {
      let findValue = options.find(
        (possibleValue: any) => possibleValue.value === item
      );
      if (findValue) {
        val.push(findValue);
      }
    });
  } else {
    let findValue = options.find(
      (possibleValue: any) => possibleValue.value === value
    );
    if (findValue) {
      val.push(findValue);
    }
  }

  return (
    <>
      <div
        className={
          errors[name] ? "select-container is-invalid" : "select-container"
        }
      >
        <Select
          {...props}
          options={options}
          onChange={(values) => onChange(values)}
          value={val}
          loadingMessage={() => "Chargement en cours..."}
          noOptionsMessage={() => "Aucune valeur à sélectionner"}
          placeholder=""
        />
      </div>
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </>
  );
};
