import React from "react";
import { Button } from "react-bootstrap";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Item from "../Common/Item";
import moment from "moment";
import * as Yup from "yup";

interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  action: "list" | "add" | "edit";
}

interface State {}

class Sections extends React.Component<Props, State> {
  public state: State = {};

  public render() {
    let endpoint = "/api/sections/";

    let formFields = [
      {
        name: "name",
        type: "text",
        label: "Nom",
        validation: Yup.string().required(
          "Vous devez renseigner le nom de la section"
        ),
      },
      {
        name: "is_active",
        type: "radio",
        label: "Etat",
        options: {
          values: [
            { value: true, name: "Actif" },
            { value: false, name: "Inactif" },
          ],
        },
        defaultValue: true,
        permissions: ["IS_ACTIVE"],
      },
      {
        name: "technical_code",
        type: "text",
        label: "Code technique",
      },
      {
        name: "updated_at",
        type: "readonly",
        label: "Dernière modification",
        transform: (value: any) => {
          let date =
            value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
          return date || "";
        },
      },
    ];
    let listFields = [
      { name: "name", label: "Nom", orderable: true },
      {
        name: "technical_code",
        label: "Code section",
        options: { width: "140px" },
        orderable: true,
      },
      {
        name: "is_active",
        label: "Actif",
        className: "text-center",
        options: { width: "100px" },
        orderable: true,
      },
      {
        name: "updated_at",
        label: "Date de modification",
        options: { width: "220px" },
        orderable: true,
        transform: (value: any) => {
          return (
            (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
          );
        },
      },
    ];
    let searchFields = [
      {
        name: "name",
        label: "Nom Section",
        options: {
          identifier: "name",
          source: "/api/sections/",
          label: "name",
          searchIsMulti: false,
        },
      },
      {
        name: "technical_code",
        label: "Code Section",
        options: {
          identifier: "technical_code",
          source: "/api/sections/",
          label: "technical_code",
          searchIsMulti: false,
        },
      },
      {
        name: "group",
        label: "Groupe",
        options: {
          identifier: "id",
          dataKey: "groups",
          source: "/api/groups/",
          label: "name",
          searchIsMulti: false,
        },
      },
    ];
    let actions = [
      {
        name: "edit",
        label: "Modifier la section",
        icon: <FontAwesomeIcon icon={faEdit} />,
        url: "/admin/sections/edit/:id",
      },
      {
        name: "delete",
        label: "Supprimer la section",
        icon: <FontAwesomeIcon icon={faTrash} />,
        deleteMessage: {
          endpoint: endpoint,
          confirm: {
            title: "Supprimer la section",
            message: "Êtes-vous sûr de vouloir supprimer cette section?",
          },
          notificationMessage: "La  section a été supprimée avec succès",
        },
      },
    ];

    return (
      <div>
        {this.props.action === "list" && (
          <span>
            <div className="button-add-user">
              <Link to="/admin/sections/add">
                <Button>
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une section
                </Button>
              </Link>
            </div>
            <h2>
              <Trans>Liste des sections</Trans>
            </h2>
            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
            />
          </span>
        )}
        {this.props.action === "add" && (
          <Item
            action="add"
            endpoint={endpoint}
            fields={formFields}
            name="Ajouter une section"
          />
        )}
        {this.props.action === "edit" && (
          <Item
            action="edit"
            endpoint={endpoint}
            fields={formFields}
            name="Modifier la section"
          />
        )}
      </div>
    );
  }
}

export default withRouter(Sections);
