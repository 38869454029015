import React, { useEffect, useState } from "react";
import axios from "axios";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchForm from "./SearchForm";

import { confirmService } from "./Confirm";
import { notificationService } from "./Notification";
import { Pagination } from "../Common/Pagination";
import Select from "react-select";

interface Field {
  name: string;
  label: string;
  transform?: Function;
  className?: string;
  options?: any;
  orderable?: boolean;
}

interface Action {
  name: string;
  label?: string;
  icon?: any;
  url?: string;
  onClick?: Function;
  deleteMessage?: any;
  isVisible?: Function;
}

type PaginationOptionsProps = {
  pageSize?: number;
  currentPage?: number;
  totalItems?: number;
};

type PaginationOptions = {
  pageSize: number;
  currentPage: number;
  totalItems: number;
};

interface ListPageProps {
  name?: string;
  endpoint: string;
  fields: Array<Field>;
  actions?: Array<Action>;
  searchFields?: any[];
  onFilterChange?(filters: string): void;
  filters?: string;
  paginationOptions?: PaginationOptionsProps;
  resultsField?: string;
  onSelectItems?(items: any[]): any;
  searchValues?: any;
}

export const ListPage = ({
  name,
  endpoint,
  fields,
  actions,
  searchFields,
  onFilterChange,
  paginationOptions,
  resultsField,
  onSelectItems,
  searchValues,
  ...props
}: ListPageProps) => {
  const [results, setResults] = useState([]);
  let lastCall = "";
  const [pagination, setPagination] = useState<PaginationOptions>({
    pageSize: paginationOptions?.pageSize || 10,
    currentPage: paginationOptions?.currentPage || 1,
    totalItems: paginationOptions?.totalItems || 0,
  });
  const [filters, setFilters] = useState("");
  const [loadInProgress, setLoadInProgress] = useState<boolean>(false);
  const [order, setOrder] = useState<string>("");
  const pageSizeOptions = [5, 10, 25, 50, 100, 250, 500];
  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const buildUrl = () => {
    let search = "";
    let ordering = "";

    if (props?.filters) {
      search = props?.filters;
    }
    if (filters) {
      if (search) {
        search += "&";
      }
      search += filters;
    }
    if (order) {
      ordering = "&ordering=" + order;
    }
    return (
      endpoint +
      "?page=" +
      pagination.currentPage +
      "&size=" +
      pagination.pageSize +
      search +
      ordering
    );
  };

  const fetchData = () => {
    let fetchUrl = buildUrl();
    if (fetchUrl === lastCall) {
      return;
    }
    lastCall = fetchUrl;
    setLoadInProgress(true);
    setResults([]);

    axios
      .get(fetchUrl)
      .then((response: any) => {
        let data: any = response.data;

        if (resultsField) {
          resultsField.split(".").forEach((field) => {
            data = data[field];
          });
        }

        if (data.results) {
          data.results.map((result: any) => {
            if (!result.id && result.sysid) {
              result.id = result.sysid;
            }
            return result;
          });
          setResults(data.results);
          setPagination({ ...pagination, totalItems: data.count });
        }
      })
      .finally(() => {
        setLoadInProgress(false);
      });
  };

  useEffect(() => {
    if (
      paginationOptions?.currentPage ||
      paginationOptions?.pageSize ||
      paginationOptions?.totalItems
    ) {
      setPagination({
        pageSize: paginationOptions?.pageSize || 10,
        currentPage: paginationOptions?.currentPage || 1,
        totalItems: paginationOptions?.totalItems || 0,
      });
    }
  }, [paginationOptions]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.currentPage, order, pagination.pageSize]);

  useEffect(() => {
    if (pagination.currentPage > 1) {
      setPagination({ ...pagination, ...{ currentPage: 1 } });
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, [props.filters, filters]);

  const renderField = (object: any, field: any): any => {
    let value = _.get(object, field.name);
    if (field.transform !== undefined) {
      value = field.transform(value, object);
    }

    if (typeof value === "boolean")
      return value ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      );
    else if (typeof value === "object" && Array.isArray(value))
      return value.join(", ");
    else return value;
  };

  const onFiltersChanged = (newFilters: any) => {
    setPagination({ ...pagination, currentPage: 1 });
    setFilters(newFilters);
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  };

  const confirmDelete = (deleteMessage: any, id: string) => {
    confirmService.show({
      title: deleteMessage.confirm.title,
      message: deleteMessage.confirm.message,
      onValidate: () => {
        axios.delete(deleteMessage.endpoint + id + "/").then(
          (response: any) => {
            // TODO: Soft reload
            notificationService.show({
              title: "Suppression effectuée avec succès",
              message: deleteMessage.notificationMessage,
              type: "success",
            });
            document.location.reload();
          },
          (error) => {
            notificationService.show({
              title: "Erreur",
              message: "Une erreur est survenue lors de la suppression",
              type: "danger",
            });
          }
        );
      },
    });
  };

  const orderingField = (field: Field) => {
    if (field.orderable) {
      if (order === field.name) {
        setOrder("-" + field.name);
      } else {
        setOrder(field.name);
      }
    }
  };

  const getOrder = (field: Field) => {
    if (field.orderable) {
      if (order === field.name) {
        return "asc";
      } else if (order === "-" + field.name) {
        return "desc";
      }
    }
    return "";
  };

  const getClassname = (field: Field) => {
    let className = field.className ? field.className + " " : "";
    if (field.orderable) {
      className += "orderable";
    }
    return className;
  };

  const checkAllItems = (items: any[]) => {
    let newCheckedItems: any[] = [];
    if (allItemsAreCheckedOnPage()) {
      checkedItems.forEach((checkedItem) => {
        if (!results.find((result) => checkedItem === result)) {
          newCheckedItems.push(checkedItem);
        }
      });
    } else {
      results.forEach((result) => {
        if (!checkedItems.find((checkedItem) => checkedItem === result)) {
          newCheckedItems.push(result);
        }
      });
      newCheckedItems = [...checkedItems, ...newCheckedItems];
    }
    setCheckedItems(newCheckedItems);
    if (typeof onSelectItems === "function") {
      onSelectItems(newCheckedItems);
    }
  };

  const allItemsAreCheckedOnPage = () => {
    if (!results.filter.length) {
      return false;
    }
    let allItemsAreChecked = true;
    results.forEach((result) => {
      if (!checkedItems.find((checkedItem) => checkedItem === result)) {
        allItemsAreChecked = false;
      }
    });
    return allItemsAreChecked;
  };

  const checkItem = (item: any) => {
    let foundItem = checkedItems.find((checkedItem) => checkedItem === item);
    let newItems;
    if (foundItem) {
      newItems = checkedItems.filter((checkedItem) => checkedItem !== item);
    } else {
      newItems = [...checkedItems, item];
    }

    setCheckedItems(newItems);
    if (typeof onSelectItems === "function") {
      onSelectItems(newItems);
    }
  };

  return (
    <div>
      {name && (
        <h2>
          <Trans>{name}</Trans>
        </h2>
      )}
      {searchFields && (
        <div className="block">
          <SearchForm
            fields={searchFields}
            filtersChange={(value: any, rawValues) => {
              onFiltersChanged(value);
            }}
            values={searchValues}
          />
        </div>
      )}
      <div className="text-right">
        <Pagination
          currentPage={pagination.currentPage}
          totalItems={pagination.totalItems}
          pageSize={pagination.pageSize}
          onPageChange={(page: number) => {
            setPagination({ ...pagination, currentPage: page });
          }}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            {typeof onSelectItems === "function" && (
              <th>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    checkAllItems(results);
                  }}
                  checked={allItemsAreCheckedOnPage() === true}
                />
              </th>
            )}
            {fields.map((field: any) => (
              <th
                key={field.name}
                style={{
                  width: field.options?.width ? field.options.width : "auto",
                }}
                className={getClassname(field)}
                onClick={() => {
                  orderingField(field);
                }}
              >
                <Trans>{field.label}</Trans>

                {getOrder(field) === "asc" && (
                  <span className="ordering">
                    <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                  </span>
                )}
                {getOrder(field) === "desc" && (
                  <span className="ordering">
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                  </span>
                )}
                {field.orderable && getOrder(field) === "" && (
                  <span className="ordering">
                    <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                  </span>
                )}
              </th>
            ))}
            {actions && actions.length > 0 && (
              <th style={{ width: "100px" }}>
                <Trans>Actions</Trans>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {results.length > 0 ? (
            results.map((result: any, key) => (
              <tr key={result.id}>
                {typeof onSelectItems === "function" && (
                  <th>
                    <input
                      type="checkbox"
                      id={result.id}
                      onChange={(e) => {
                        checkItem(result);
                      }}
                      checked={
                        checkedItems.find(
                          (checkedItem) => checkedItem === result
                        ) !== undefined
                      }
                    />
                  </th>
                )}
                {fields.map((field: any) => (
                  <td
                    key={field.label}
                    title={field.label}
                    className={field.className ? field.className : ""}
                  >
                    {renderField(result, field)}
                  </td>
                ))}
                {actions && actions.length > 0 && (
                  <td className="text-center actions">
                    {actions.map((action: Action) => {
                      return !action.hasOwnProperty("isVisible") ||
                        (typeof action.isVisible === "function" &&
                          action?.isVisible(result)) ? (
                        <span
                          className="link"
                          key={action.name}
                          title={action.label}
                        >
                          {action.name === "delete" ? (
                            <span
                              onClick={() => {
                                confirmDelete(action.deleteMessage, result.id);
                              }}
                            >
                              {action.icon}
                            </span>
                          ) : typeof action.onClick === "function" ? (
                            <span
                              onClick={() => {
                                if (typeof action.onClick === "function") {
                                  action.onClick(result);
                                }
                              }}
                            >
                              {action.icon}
                            </span>
                          ) : action.url !== undefined ? (
                            <Link
                              to={action.url.replace(":id", result.id)}
                              className="mr-2"
                            >
                              {action.icon}
                            </Link>
                          ) : (
                            <span key={action.name}></span>
                          )}
                        </span>
                      ) : (
                        <span key={action.name}></span>
                      );
                    })}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={
                  actions && actions.length
                    ? fields.length + 2
                    : fields.length + 1
                }
              >
                {loadInProgress === true ? (
                  <Trans>Chargement en cours...</Trans>
                ) : (
                  <Trans>Il n'y a aucun résultat à afficher</Trans>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="text-right">
        <Pagination
          currentPage={pagination.currentPage}
          totalItems={pagination.totalItems}
          pageSize={pagination.pageSize}
          onPageChange={(page: number) => {
            setPagination({ ...pagination, currentPage: page });
          }}
        />
        <div className="pageSize">
          Résultats par page:{" "}
          <Select
            options={pageSizeOptions.map((pageSizeOption) => {
              return { label: pageSizeOption, value: pageSizeOption };
            })}
            onChange={(pageSize: any) => {
              setPagination({ ...pagination, pageSize: pageSize.value });
            }}
            value={{ value: pagination.pageSize, label: pagination.pageSize }}
            loadingMessage={() => "Chargement en cours..."}
            noOptionsMessage={() => "Aucune valeur à sélectionner"}
            placeholder=""
          />
        </div>
      </div>
    </div>
  );
};

export default ListPage;
