import React from "react";
import axios from "axios";

import { Button } from "react-bootstrap";
import ListPage from "../Common/ListPage";
import "./Users.scss";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlusCircle,
  faUserNinja,
} from "@fortawesome/free-solid-svg-icons";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Item from "../Common/Item";
import { User } from "@sentry/browser";
import { Field } from "../Common/FieldsInterface";
import moment from "moment";
import dataService from "../Common/dataService";
import * as Yup from "yup";
import { notificationService } from "../Common/Notification";
import { auth } from "auth";

interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  action: "list" | "add" | "edit";
}

interface State { }

class Users extends React.Component<Props, State> {
  public confirm: any;
  public state: State = {};

  public transformItemOnSave(user: User) {
    if (!user.password) {
      delete user.password;
    }
    if (user.contracts) {
      user.contracts = user.contracts.map((contract: any) => {
        if (typeof contract !== "string") {
          return contract.sysid;
        }
        return contract;
      });
    }
    if (user.customers) {
      user.customers = user.customers.map((customer: any) => {
        if (typeof customer !== "string") {
          return customer.sysid;
        }
        return customer;
      });
    }
    return user;
  }

  public render() {
    let endpoint = "/api/users/";

    let formFields: Field[] = [
      {
        name: "name",
        type: "text",
        label: "Nom",
        validation: Yup.string().required("Vous devez renseigner le nom"),
      },
      {
        name: "email",
        type: "text",
        label: "Email",
        validation: Yup.string()
          .email("Vous devez renseigner un e-mail valide")
          .required("L'email ne peut être vide"),
      },
      {
        name: "password",
        type: "password",
        label: "Mot de passe",
      },
      {
        name: "is_active",
        type: "radio",
        label: "Etat",
        options: {
          values: [
            { value: true, name: "Actif" },
            { value: false, name: "Inactif" },
          ],
        },
        defaultValue: true,
        permissions: ["IS_ACTIVE"],
      },
      { name: "photo", type: "file", label: "Photo" },

      {
        name: "groups",
        type: "select-multiple",
        label: "Groupes rattachés",
        options: {
          values: dataService.getData("groups", { is_active: true }),
        },
      },
      {
        name: "last_login",
        type: "readonly",
        label: "Dernière connexion",
        transform: (value: any) => {
          let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
          return date || "";
        },
      },
    ];

    let listFields = [
      {
        name: "name",
        label: "Nom",
        orderable: true,
      },
      {
        name: "groups",
        label: "Groupes",
        transform: (values: any) => {
          let dataServiceGroups = dataService.getData("groups");
          let groups = values.map((value: number) => {
            let group = dataServiceGroups.find(
              (dataServiceGroup: any) => dataServiceGroup.id === value
            );
            return group?.name || value;
          });
          return groups.join(", ");
        },
      },
      {
        name: "is_active",
        label: "Actif",
        className: "text-center",
        orderable: true,
        options: { width: "100px" },
      },
      {
        name: "last_login",
        label: "Dernière connexion",
        transform: (value: any) => {
          return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
        },
        orderable: true,
        options: { width: "210px" },
      },
    ];

    let searchFields = [
      {
        name: "name",
        label: "Nom",
        options: {
          source: "/api/users/",
          identifier: "name",
          searchIsMulti: false,
        },
      },
      {
        name: "groups",
        label: "Groupe",
        options: {
          identifier: "id",
          dataKey: "groups",
          source: "/api/groups/",
          label: "name",
          searchIsMulti: true,
        },
      },
    ];

    let actions = [
      {
        name: "edit",
        label: "Modifier l'utilisateur",
        icon: <FontAwesomeIcon icon={faEdit} />,
        url: "/admin/users/edit/:id",
      },
      {
        name: "delete",
        label: "Supprimer l'utilisateur",
        icon: <FontAwesomeIcon icon={faTrash} />,
        deleteMessage: {
          endpoint: endpoint,
          confirm: {
            title: "Supprimer l'utilisateur",
            message: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
          },
          notificationMessage: "L'utilisateur a été supprimé avec succès",
        },
      },
    ];

    let connectAsAction: any = {
      name: "connect-as",
      label: "Se connecter en tant que",
      icon: <FontAwesomeIcon icon={faUserNinja} />,
      onClick: (user: any) => {
        axios.post("/api/login/as/", { user_id: user.id }).then(
          () => {
            notificationService.show({
              type: "success",
              title: "Succès",
              message:
                "Vous êtes maintenant connectés en tant que " +
                user.name +
                ". La page va maintenant se recharger",
            });

            setTimeout(() => {
              window.location.reload();
            }, 2500);
          },
          (error) => {
            let message = "";
            if (error.response.status === 401) {
              message = "Vous n'êtes pas autorisés à utiliser cette fonction";
            }

            notificationService.show({
              type: "danger",
              title: "Une erreur est survenue",
              message,
            });
          }
        );
      },
    };

    if (auth.canAccess("LOGIN_AS")) {
      actions.push(connectAsAction);
    }
    return (
      <div>
        {this.props.action === "list" && (
          <div className="block">
            <div className="button-add-user">
              <Link to="/admin/users/add">
                <Button>
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un utilisateur
                </Button>
              </Link>
            </div>
            <h2>
              <Trans>Liste des utilisateurs</Trans>
            </h2>

            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
            />
          </div>
        )}
        {this.props.action === "add" && (
          <Item
            action="add"
            endpoint={endpoint}
            fields={formFields}
            name="Ajouter un utilisateur"
            transformItemOnSave={this.transformItemOnSave}
          />
        )}
        {this.props.action === "edit" && (
          <Item
            action="edit"
            endpoint={endpoint}
            fields={formFields}
            name="Modifier l'utilisateur"
            transformItemOnSave={this.transformItemOnSave}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Users);
