import React, { useState } from "react";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import dataService from "components/Common/dataService";
import Item from "components/Common/Item";
import axios from "axios";

interface ParamTypes {
  id: string;
}

export const AppDevices = (params: any) => {
  const { id } = useParams<ParamTypes>();

  const history = useHistory();
  const [searchValues, setSearchValues] = useState<any>({});

  const endpoint = "/api/user-devices/";

  let listFields = [
    {
      name: "device_type",
      label: "Type device",
      transform: (value: any) => {
        let deviceType = dataService
          .getData("deviceTypes")
          .find((deviceType: any) => deviceType.id === value);
        return deviceType?.name;
      },
    },
    {
      name: "serial_number",
      label: "Numéro de série",
    },
    {
      name: "user__email",
      label: "E-mail utilisateur",
    },
    {
      name: "last_firmware_version",
      label: "Dernier firmware connu",
    },
    {
      name: "last_update_date",
      label: "Date de rattachement",
      transform: (value: any) => {
        let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  let searchFields = [
    {
      name: "user",
      label: "Nom",
      options: {
        identifier: "id",
        source: "/api/app-users/",
        isMulti: false,
      },
    },
    {
      name: "serial_number",
      label: "Numéro de série",
      type: "text",
    },
    {
      name: "firmware",
      label: "Firmware",
      options: {
        identifier: "id",
        source: "/api/firmwares/",
        label: "name",
      },
    },
  ];

  let actions = [
    {
      name: "view",
      label: "Voir le device",
      icon: <FontAwesomeIcon icon={faMobileAlt} />,
      url: "/firmwares-ota-historic/devices/view/:id",
    },
    {
      name: "log",
      label: "Logs",
      icon: <FontAwesomeIcon icon={faExchangeAlt} />,
      onClick: (value: any) => {
        history.push(
          "/firmwares-ota-historic/logs/" + value.last_update__id + "/"
        );
      },
      isVisible: (value: any) => {
        return value.last_update__id;
      },
    },
  ];

  let formFields = [
    {
      name: "serial_number",
      label: "Numéro de série",
      type: "readonly",
    },
    {
      name: "user__email",
      label: "Utilisateur associé",
      type: "readonly",
    },
    {
      name: "created_at",
      label: "Date de création",
      transform: (value: any) => {
        return moment(value).format("DD/MM/YYYY à HH:mm:ss");
      },
      type: "readonly",
    },
    // {
    //   name: "last_login",
    //   label: "Date dernière connexion",
    // },
    {
      name: "last_firmware_version",
      label: "Dernière version firmware",
      type: "readonly",
    },
    {
      name: "historique",
      label: "Historique des mises à jour",
      type: "readonly",
      transform: (value: any) => {
        return (
          <ListPage
            endpoint={"/api/firmwares-ota-historic/?device=" + id + "&"}
            fields={[
              {
                name: "firmware__name",
                label: "Firmware",
                transform: (value: any, object: any) => {
                  return (
                    <Link to={"/firmwares/edit/" + object.firmware}>
                      {value}
                    </Link>
                  );
                },
                orderable: true,
              },
              {
                name: "device__user__email",
                label: "Email utilisateur",
                transform: (value: any, object: any) => {
                  return (
                    <Link
                      to={
                        "/firmwares-ota-historic/users/" + object.device__user
                      }
                    >
                      {value}
                    </Link>
                  );
                },
                orderable: true,
              },
              {
                name: "device__device_type",
                label: "Type device",
                transform: (value: any) => {
                  let deviceType = dataService
                    .getData("deviceTypes")
                    .find((deviceType: any) => deviceType.id === value);
                  return deviceType?.name || value;
                },
              },
              {
                name: "created_at",
                label: "Dernière MAJ",
                transform: (value: any) => {
                  return (
                    (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) ||
                    ""
                  );
                },
                orderable: true,
                options: { width: "220px" },
              },
            ]}
            name=""
            actions={[
              {
                name: "log",
                label: "Logs",
                icon: <FontAwesomeIcon icon={faExchangeAlt} />,
                onClick: (value: any) => {
                  history.push(
                    "/firmwares-ota-historic/logs/" + value.id + "/"
                  );
                },
                isVisible: (value: any) => {
                  return value.id;
                },
              },
            ]}
          />
        );
      },
    },
  ];

  let searchParams = new URLSearchParams(document.location.search);
  let userSearch = searchParams.get("user");
  if (userSearch) {
    axios.get("/api/app-users/" + userSearch + "/").then((response: any) => {
      let user = response.data;
      let newSearchValues = {
        filterValues: {
          user: [user?.id],
        },
        formValue: {
          user: [{ label: user?.name, value: user?.id }],
        },
      };

      if (JSON.stringify(newSearchValues) !== JSON.stringify(searchValues)) {
        setSearchValues(newSearchValues);
      }
    });
  }

  return (
    <>
      {params?.action === "list" && (
        <div>
          <div className="block">
            <h2>
              <Trans>Liste des devices</Trans>
            </h2>

            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
              searchValues={searchValues}
            />
          </div>
        </div>
      )}
      {params?.action === "view" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Fiche device"
        />
      )}
    </>
  );
};
