import React from "react";
import { Button } from "react-bootstrap";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Item from "../Common/Item";
import moment from "moment";
import * as Yup from "yup";

interface ParamTypes {
  id: string;
}

export const DeviceTypes = (props: any) => {
  const endpoint = "/api/device-types/";

  let formFields = [
    {
      name: "name",
      type: "text",
      label: "Nom",
      validation: Yup.string().required(
        "Vous devez renseigner le nom du device type"
      ),
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, name: "Actif" },
          { value: false, name: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "technical_code",
      type: "text",
      label: "Code technique",
    },
    {
      name: "comment",
      type: "text",
      label: "Commentaires",
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date = value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  let listFields = [
    { name: "name", label: "Nom", orderable: true },
    {
      name: "technical_code",
      label: "Code technique",
      options: { width: "200px" },
      orderable: true,
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];
  let searchFields = [
    {
      name: "name",
      label: "Nom",
      options: {
        identifier: "name",
        source: "/api/device-types/",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];
  let actions = [
    {
      name: "edit",
      label: "Modifier le device type",
      icon: <FontAwesomeIcon icon={faEdit} />,
      url: "/admin/device-types/edit/:id",
    },
    {
      name: "delete",
      label: "Supprimer le device type",
      icon: <FontAwesomeIcon icon={faTrash} />,
      deleteMessage: {
        endpoint: endpoint,
        confirm: {
          title: "Supprimer le device type",
          message: "Êtes-vous sûr de vouloir supprimer ce device type?",
        },
        notificationMessage: "Le device type a été supprimé avec succès",
      },
    },
  ];

  return (
    <div>
      {props.action === "list" && (
        <span>
          <div className="button-add-user">
            <Link to="/admin/device-types/add">
              <Button>
                <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un device type
              </Button>
            </Link>
          </div>
          <h2>
            <Trans>Liste des device types</Trans>
          </h2>
          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </span>
      )}
      {props.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un device type"
        />
      )}
      {props.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le device type"
        />
      )}
    </div>
  );
};
