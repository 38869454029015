import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";

registerLocale("fr", fr);
setDefaultLocale("fr");

export type Action = {
  type?: "now" | "scheduled";
  sendingDate?: any;
};

type SendingTypeProps = {
  onChange(action: any): any;
  value: Action;
};

export const SendingType = ({ onChange, value }: SendingTypeProps) => {
  const [action, setAction] = useState<Action>(value);
  return (
    <Row>
      <Col xs={6}>
        <Form.Check
          name="sendingType"
          value="now"
          id="now"
          type="radio"
          label="Envoi immédiat"
          checked={action && action?.type === "now"}
          inline
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let _action = { ...action };
            _action.type = "now";
            setAction({ ..._action });
            onChange(_action);
          }}
        />
      </Col>
      <Col xs={6}>
        <Form.Check
          name="sendingType"
          value="scheduled"
          id="scheduled"
          type="radio"
          label="Envoi planifié"
          checked={action && action?.type === "scheduled"}
          inline
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let _action = { ...action };
            _action.type = "scheduled";
            setAction({ ..._action });
            onChange(_action);
          }}
        />
        {action?.type === "scheduled" && (
          <span data-testid="datepicker">
            <DatePicker
              selected={
                value.sendingDate ? new Date(value.sendingDate) : new Date()
              }
              onChange={(date: any) => {
                let _action = { ...action };
                _action.sendingDate =
                  moment(date).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                _action.type = "scheduled";
                setAction({ ..._action });
                onChange(_action);
              }}
              locale="fr"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="time"
              dateFormat="dd/MM/yyyy HH:mm"
            />
          </span>
        )}
      </Col>
    </Row>
  );
};
