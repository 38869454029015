import React from "react";
import { Button, Modal } from "react-bootstrap";

type Options = {
  title: string;
  message?: string;
  onCancel?(): void;
  onValidate(): void;
};

interface Props {}

interface State {
  show: boolean;
  options: Options | null;
}

export class Confirm extends React.Component<Props, State> {
  public state: State = {
    show: false,
    options: null,
  };

  public show(options: Options) {
    this.setState({ show: true, options });
  }

  public close() {
    this.setState({ show: false });
  }

  public cancel() {
    this.close();
    if (this.state.options?.onCancel) {
      this.state.options.onCancel();
    }
  }

  public validate() {
    this.close();
    if (this.state.options?.onValidate) {
      this.state.options.onValidate();
    }
  }
  public render() {
    return (
      <>
        <Modal show={this.state.show} onHide={() => this.close()}>
          <Modal.Header closeButton>
            {this.state.options?.title && (
              <Modal.Title>{this.state.options.title}</Modal.Title>
            )}
          </Modal.Header>
          {this.state.options?.message && (
            <Modal.Body
              dangerouslySetInnerHTML={{
                __html: this.state.options?.message || "",
              }}
            ></Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.cancel()}>
              Annuler
            </Button>
            <Button variant="primary" onClick={() => this.validate()}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class ConfirmService {
  public instance: any;

  public show = (options: Options) => this.instance.show(options);
}

export let confirmService = new ConfirmService();

// export default Confirm;
