import React, { useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/core";
import axios from "axios";

export const Loader = () => {
  const override = css`
    display: block;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 auto;
    width: 100%;
    z-index: 2000;
  `;

  const [isLoading, setIsLoading] = useState(false);

  const disableLoaderForThesesRoutes = [""];

  const setInteceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        setIsLoading(false);
        return response;
      },
      async (error) => {
        setIsLoading(false);
        // Trow errr again (may be need for some other catch)
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use((config) => {
      let loaderIsDisabledForThisUrl = false;
      disableLoaderForThesesRoutes.forEach((route: string) => {
        if (route && config.url?.startsWith(route)) {
          loaderIsDisabledForThisUrl = true;
        }
      });

      if (!loaderIsDisabledForThisUrl) {
        setIsLoading(true);
      }

      return config;
    });
  };
  setInteceptors();

  return (
    <>
      <div>
        <BarLoader
          height={6}
          color={"#215370"}
          css={override}
          loading={isLoading}
        />
      </div>
    </>
  );
};
