import React from "react";
import axios from "axios";
import "./MessageList.scss";

interface State {
  messages: any[];
}

interface Props {}

class MessageList extends React.Component<Props, State> {
  public state: State = {
    messages: [],
  };

  public componentDidMount() {
    axios.get("/api/messages/?is_active=true").then((response: any) => {
      let data = response.data;
      if (data.results) {
        // TODO: Remove this when api will be able to sort data
        data.results.map((result: any) => {
          if (!result.id && result.sysid) {
            result.id = result.sysid;
          }
          return result;
        });
        data.results.sort(function (a: any, b: any) {
          return b.updated_at - a.updated_at;
        });
        this.setState({
          messages: data.results,
        });
      }
    });
  }

  public render() {
    return (
      <div className="messages">
        {this.state.messages.map((message: any) => (
          <div className="message" key={message.id}>
            <header>{message.title}</header>
            <section>
              <p dangerouslySetInnerHTML={{ __html: message.content }} />
            </section>
          </div>
        ))}
      </div>
    );
  }
}

export default MessageList;
