import React from "react";
import { Button } from "react-bootstrap";
import ListPage from "../Common/ListPage";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Item from "../Common/Item";
import { Field } from "../Common/FieldsInterface";
import moment from "moment";
import dataService from "../Common/dataService";
import * as Yup from "yup";

interface RouterProps {
  id: string;
}

interface Props extends RouteComponentProps<RouterProps> {
  action: "list" | "add" | "edit";
}

interface State {}

class Messages extends React.Component<Props, State> {
  public state: State = {
    dataFromApi: {
      groups: {
        endpoint: "/api/groups/",
        data: [],
      },
    },
  };

  public render() {
    let endpoint = "/api/messages/";

    let formFields: Field[] = [
      {
        name: "title",
        type: "text",
        label: "Titre message",
        validation: Yup.string().required(
          "Vous devez renseigner le titre du message"
        ),
      },
      {
        name: "type",
        type: "select",
        label: "Type message",
        options: {
          values: dataService.getData("messageTypes"),
          identifier: "id",
          label: "type",
        },
        validation: Yup.mixed().required(
          "Vous devez renseigner le type du message"
        ),
      },
      {
        name: "content",
        type: "richtext",
        label: "Contenu message",
        validation: Yup.string().required(
          "Vous devez renseigner le contenu du message"
        ),
      },
      {
        name: "is_active",
        type: "radio",
        label: "Etat",
        options: {
          values: [
            { value: true, name: "Actif" },
            { value: false, name: "Inactif" },
          ],
        },
        defaultValue: true,
        permissions: ["IS_ACTIVE"],
      },
      {
        name: "updated_at",
        type: "readonly",
        label: "Dernière modification",
        transform: (value: any) => {
          let date =
            value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
          return date || "";
        },
      },
    ];

    let listFields = [
      {
        name: "title",
        label: "Titre Message",
      },
      {
        name: "type__type",
        label: "Type",
      },
      {
        name: "is_active",
        label: "Actif",
        className: "text-center",
        options: { width: "100px" },
        orderable: true,
      },
      {
        name: "updated_at",
        label: "Date de modification",
        transform: (value: any) => {
          return (
            (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
          );
        },
        orderable: true,
        options: { width: "220px" },
      },
    ];

    let searchFields = [
      {
        name: "title",
        label: "Titre message",
        options: {
          identifier: "title",
          source: "/api/messages/",
          label: "title",
          isMulti: false,
        },
      },
    ];

    let actions = [
      {
        name: "edit",
        label: "Modifier le message",
        icon: <FontAwesomeIcon icon={faEdit} />,
        url: "/admin/messages/edit/:id",
      },
      {
        name: "delete",
        label: "Supprimer le message",
        icon: <FontAwesomeIcon icon={faTrash} />,
        deleteMessage: {
          endpoint: endpoint,
          confirm: {
            title: "Supprimer le message",
            message: "Êtes-vous sûr de vouloir supprimer ce message?",
          },
          notificationMessage: "Le  message a été supprimé avec succès",
        },
      },
    ];

    return (
      <div>
        {this.props.action === "list" && (
          <div className="block">
            <div className="button-add-user">
              <Link to="/admin/messages/add">
                <Button>
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un message
                </Button>
              </Link>
            </div>
            <h2>
              <Trans>Liste des messages</Trans>
            </h2>

            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
            />
          </div>
        )}
        {this.props.action === "add" && (
          <Item
            action="add"
            endpoint={endpoint}
            fields={formFields}
            name="Ajouter un message"
          />
        )}
        {this.props.action === "edit" && (
          <Item
            action="edit"
            endpoint={endpoint}
            fields={formFields}
            name="Modifier le message"
          />
        )}
      </div>
    );
  }
}

export default withRouter(Messages);
