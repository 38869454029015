import React, { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, FormikHelpers } from "formik";
import { Row, Col, Form as FormBootstrap } from "react-bootstrap";
import Select from "react-select";
import dataService from "../Common/dataService";

import fr from "date-fns/locale/fr";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { notificationService } from "components/Common/Notification";
import AutocompleteField from "../Common/AutocompleteField";
import JoditEditor from "jodit-react";
import { SendingType } from "../Common/SendingType";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface ParamTypes {
  id: string;
}

interface Send {
  firmware?: any;
  device_type?: any;
  title?: string;
  content?: string;
  sending_schedule?: "now" | "scheduled";
  sending_date?: Date;
  sending_type?: {
    type: string;
    sendingDate: Date;
  };
  recipients_count?: number;
  id?: number;
  created_at?: number;
  updated_at?: number;
}

export const SendForm = (params: any) => {
  const { id } = useParams<ParamTypes>();
  const endpoint = "/api/email-messages/";

  const [send, setSend] = useState<Send>({});
  const [recipientCount, setRecipientCount] = useState<number>(0);

  const editor = useRef(null);
  const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  let history = useHistory();
  let formik: any = {};

  const toApi = (send: any) => {
    let newSend = Object.assign({}, send);
    newSend.firmware = send.firmware?.value;
    newSend.device_type = send.device_type?.value;
    newSend.sending_schedule = send.sending_type?.type;
    newSend.sendind_date = send.sending_type?.sendingDate;
    delete newSend.sending_type;
    return newSend;
  };

  const fromApi = async (data: any) => {
    let newSend: Send = Object.assign({}, data);

    try {
      await axios
        .get("/api/firmwares/" + data.firmware + "/")
        .then((firmwareResponse: any) => {
          newSend.firmware = {
            value: firmwareResponse.data.id,
            label: firmwareResponse.data.name,
          };
        });
    } catch (error) {
      notificationService.show({
        type: "danger",
        title: "Erreur firmware",
        message: `Le firmware (${data.firmware}) associé a cet envoi n'existe pas`,
      });
    }
    newSend.device_type = deviceTypes.find(
      (deviceType: any) => deviceType.value === data.device_type
    );

    newSend.sending_type = {
      type: data.sending_schedule,
      sendingDate: data.sendind_date,
    };

    return new Promise((resolve, reject) => {
      resolve(newSend);
    });
  };

  useEffect(() => {
    if (params.action === "view" && id) {
      axios.get(endpoint + id + "/").then((response) => {
        fromApi(response.data).then((newSend: any) => {
          setSend(newSend);
          setRecipientCount(newSend?.recipients_count || 0);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, id]);

  const deviceTypes = dataService
    .getData("deviceTypes", { is_active: true })
    .map((group: any) => {
      return { value: group.id, label: group.name };
    });
  deviceTypes.unshift({ value: "", label: "Tous" });

  const saveSend = (sendToSave: Send) => {
    let sendData = toApi(sendToSave);
    axios.post(endpoint, sendData).then(() => {
      notificationService.show({
        title: "Succès",
        message: "L'envoi a été créé avec succès!",
        type: "success",
      });
      setTimeout(() => {
        history.push("/sends");
      }, 3000);
    });
  };

  const updateRecipientCount = () => {
    let filters = "?";
    if (formik?.values?.device_type?.value) {
      filters += "device_type=" + formik.values?.device_type?.value + "&";
    }
    if (formik?.values?.firmware?.value) {
      filters += "firmware=" + formik.values?.firmware?.value + "&";
    }

    axios
      .get("/api/recipient-count/" + filters)
      .then((response) => setRecipientCount(response?.data?.count));
  };

  return (
    <div className="block">
      <h2>{!id ? <>Nouvel envoi</> : <>Voir l'envoi #{id}</>}</h2>
      <Formik
        initialValues={send}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
          setTimeout(() => {
            let newSend = { ...send, ...values };
            setSend(newSend);
            saveSend(newSend);
            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          getFieldProps,
          isSubmitting,
          touched,
          errors,
          setFieldValue,
        }) => {
          formik.values = values;
          return (
            <Form>
              <h3>Informations</h3>
              <div className="ml-4">
                <FormBootstrap.Group as={Row} controlId="device-type">
                  <FormBootstrap.Label column sm="3">
                    Type de device cible
                  </FormBootstrap.Label>
                  <Col sm="9">
                    {send.id !== undefined && <>{values?.device_type?.label}</>}
                    {send.id === undefined && (
                      <>
                        <div
                          className={
                            (touched.deviceTypes || isSubmitting) &&
                            errors.device_type
                              ? "select-container is-invalid"
                              : "select-container"
                          }
                        >
                          <Select
                            options={deviceTypes}
                            {...getFieldProps("device_type")}
                            onChange={(values) => {
                              setFieldValue("device_type", values);
                              setTimeout(() => updateRecipientCount());
                            }}
                            isMulti={false}
                            loadingMessage={() => "Chargement en cours..."}
                            noOptionsMessage={() =>
                              "Aucune valeur à sélectionner"
                            }
                            placeholder=""
                          />
                        </div>
                        <FormBootstrap.Control.Feedback type="invalid">
                          {errors.deviceTypes}
                        </FormBootstrap.Control.Feedback>
                      </>
                    )}
                  </Col>
                </FormBootstrap.Group>

                <Row>
                  <Col sm="12">
                    <FormBootstrap.Group as={Row} controlId="firmware">
                      <FormBootstrap.Label column sm="3">
                        Firmware associé
                      </FormBootstrap.Label>
                      <Col sm="9">
                        {send.id !== undefined && (
                          <>{values?.firmware?.label}</>
                        )}
                        {send.id === undefined && (
                          <AutocompleteField
                            field={{
                              options: {
                                source: "/api/firmwares/",
                                searchIsMulti: false,
                                isActive: true,
                              },
                            }}
                            {...getFieldProps("firmware")}
                            onSelect={(values: any) => {
                              setFieldValue("firmware", values);
                              setTimeout(() => updateRecipientCount());
                            }}
                            defaultOptions={[{ value: "", label: "Tous" }]}
                          />
                        )}
                      </Col>
                    </FormBootstrap.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <FormBootstrap.Group as={Row} controlId="firmware">
                      <FormBootstrap.Label column sm="3">
                        Nombre d'utilisateurs ciblés
                      </FormBootstrap.Label>
                      <Col sm="9">
                        {recipientCount}
                        <FontAwesomeIcon icon={faShareAlt} className="ml-2" />
                      </Col>
                    </FormBootstrap.Group>
                  </Col>
                </Row>
              </div>

              {(recipientCount > 0 || send.id !== undefined) && (
                <>
                  <h3>Mail</h3>
                  <div className="ml-4">
                    <FormBootstrap.Group as={Row} controlId="title">
                      <FormBootstrap.Label column sm="3">
                        Titre
                      </FormBootstrap.Label>
                      <Col sm="9">
                        {send.id !== undefined && <>{values?.title}</>}
                        {send.id === undefined && (
                          <FormBootstrap.Control
                            type="text"
                            value={values?.title || ""}
                            {...getFieldProps("title")}
                          />
                        )}
                      </Col>
                    </FormBootstrap.Group>

                    <FormBootstrap.Group as={Row} controlId="content">
                      <FormBootstrap.Label column sm="3">
                        Contenu
                      </FormBootstrap.Label>
                      <Col sm="9">
                        <>
                          <div
                            className={
                              errors.content
                                ? "select-container is-invalid"
                                : "select-container"
                            }
                          >
                            {send.id !== undefined && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values["content"],
                                }}
                              />
                            )}
                            {send.id === undefined && (
                              <JoditEditor
                                value={values["content"] || ""}
                                config={editorConfig}
                                ref={editor}
                                onChange={(content: any) =>
                                  setFieldValue("content", content)
                                }
                              />
                            )}
                          </div>
                          <FormBootstrap.Control.Feedback type="invalid">
                            {errors.content}
                          </FormBootstrap.Control.Feedback>
                        </>
                      </Col>
                    </FormBootstrap.Group>

                    <FormBootstrap.Group as={Row} controlId="sending_type">
                      <FormBootstrap.Label column sm="3">
                        Options d'envoi
                      </FormBootstrap.Label>
                      <Col sm="9">
                        {send.id !== undefined && (
                          <>
                            {values?.sending_type?.type === "now"
                              ? "Immédiat"
                              : `Planifié (${
                                  values?.sending_type?.sendingDate
                                    ? moment(
                                        values?.sending_type?.sendingDate
                                      ).format("DD/MM/YYYY à HH:mm:ss")
                                    : "aucune date"
                                })`}
                          </>
                        )}
                        {send.id === undefined && (
                          <SendingType
                            value={values["sending_type"]}
                            onChange={(values) => {
                              setFieldValue("sending_type", values);
                            }}
                          />
                        )}
                      </Col>
                    </FormBootstrap.Group>
                  </div>
                </>
              )}
              <div className="text-center btns">
                {send.id === undefined && (
                  <button
                    type="submit"
                    className="btn btn-large btn-primary"
                    disabled={
                      !recipientCount ||
                      !values["sending_type"] ||
                      !values["title"] ||
                      !values["content"]
                    }
                  >
                    Enregistrer
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
