import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import AutocompleteField from "./AutocompleteField";
import { Field } from "./FieldsInterface";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUndo } from "@fortawesome/free-solid-svg-icons";
import dataService from "./dataService";
import queryString from "query-string";

registerLocale("fr", fr);
setDefaultLocale("fr");

interface Props {
  name?: string;
  fields: Field[];
  filtersChange(value: any, rawFilterValues: any): void;
  values?: any;
  id?: string;
}

const SearchForm = ({ name, fields, filtersChange, values, id }: Props) => {
  // Stocke les valeurs brutes des formulaires (avec les spécificités de format de chaque formulaire)
  const [formValue, setFormValue] = useState<any>({});

  // Stocke les valeurs formatées et standardisées des filtres (couple clé:valeur)
  const [filterValues, setFilterValues] = useState<any>({});

  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [hasSeeMoreOption, setSeeMoreOption] = useState<boolean>(false);

  const resetSearch = () => {
    setFormValue({});
    setFilterValues({});
  };

  const search = () => {
    let filterString = "";
    Object.keys(filterValues).forEach((filterName) => {
      if (!filterValues[filterName]) {
        return;
      }
      if (filterValues[filterName] instanceof Array) {
        let filterNameSuffix =
          filterValues[filterName].length > 1 &&
            filterName.substring(filterName.length - 4) !== "__in"
            ? "__in"
            : "";
        filterString += `&${filterName}${filterNameSuffix}=${filterValues[
          filterName
        ].join(",")}`;
      } else {
        filterString += `&${filterName}=${filterValues[filterName]}`;
      }
    });
    filtersChange(filterString, { formValue, filterValues });
  };

  useEffect(() => {
    let newHasSeeMoreOption = false;
    fields.forEach((field: any) => {
      if (field?.seeMore) {
        newHasSeeMoreOption = true;
      }
    });
    setSeeMoreOption(newHasSeeMoreOption);

    readParamsFromQueryString();
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    if (values) {
      if (values.formValue) {
        setFormValue(values.formValue);
      }
      if (values.filterValues) {
        setFilterValues(values.filterValues);
      }
    }
  }, [values]);

  const setFieldValue = (
    fieldName: string,
    fieldValue: any,
    filterValue: any = null
  ) => {
    //filterValue = filterValue || fieldValue;
    if (!filterValue) {
      if (fieldValue && fieldValue.hasOwnProperty("value")) {
        filterValue = fieldValue.value ? fieldValue : null;
      } else if (fieldValue) {
        filterValue = fieldValue;
      }
    }

    let newFormValue = { ...formValue, ...{ [fieldName]: fieldValue } };
    let newFilterValues = { ...filterValues, ...{ [fieldName]: filterValue } };
    setFormValue(newFormValue);
    setFilterValues(newFilterValues);
  };

  const getFieldValue = (fieldName: string): any => {
    return formValue[fieldName] || null;
  };

  const readParamsFromQueryString = () => {
    // Existing filters in querystring? format: ?filterName=filterValue;filterLabel
    let parseQueryString = queryString.parse(document.location.search);
    let atLeastOneFilterExist = false;

    fields.forEach((field: Field) => {
      let filterValue = parseQueryString[field.name];
      if (filterValue) {
        atLeastOneFilterExist = true;

        let dataServiceKey = field.options?.dataKey
          ? field.options?.dataKey
          : field.name;
        let datarefs = dataService.getData(dataServiceKey);
        if (datarefs) {
          let identifier = field.options?.identifier || "id";
          let label = field.options?.label || "name";

          let dataref = datarefs.find((data: any) => {
            return data[identifier]?.toString() === filterValue?.toString();
          });

          if (dataref && dataref[identifier] && dataref[label]) {
            let newFormValue = {
              [field.name]: {
                value: dataref[identifier],
                label: dataref[label],
              },
            };
            setFormValue({ ...formValue, ...newFormValue });
            setFilterValues({
              ...filterValues,
              ...{ [field.name]: filterValue },
            });
          }
        }
      }
    });

    if (atLeastOneFilterExist) {
      setTimeout(() => {
        search();
      });
    }
  };

  return (
    <div>
      {name && (
        <h2>
          <Trans>{name}</Trans>
        </h2>
      )}

      <Form
        className="searchForm"
        onSubmit={(e: any) => {
          e.preventDefault();
          search();
        }}
      >
        <Row>
          {fields.map((field) => {
            // if (field?.seeMore && !seeMore) {
            //   return <React.Fragment key={field.name} />;
            // }
            return (
              <Col
                key={field.name}
                className={field.className ? field.className : ""}
              >
                <Form.Group as={Row} controlId={"form" + id + "-" + field.name}>
                  <Form.Label column sm={3}>
                    {field.label}
                  </Form.Label>
                  <Col sm={9}>
                    {(() => {
                      switch (field["type"]) {
                        case "datepicker":
                          return (
                            <DatePicker
                              onChange={(date) => {
                                let filterValue =
                                  moment(date)
                                    .utcOffset(0)
                                    .format("YYYY-MM-DDTHH:mm:ss") + "Z";
                                setFieldValue(field.name, date, filterValue);
                              }}
                              selected={getFieldValue(field.name)}
                              showTimeSelect
                              locale="fr"
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="dd/MM/yyyy HH:mm"
                              placeholderText="Choisissez une date..."
                              className={"input-" + field.name}
                            />
                          );
                        case "select-multiple":
                        case "select":
                          let possibleValues: Array<{
                            label: string | undefined;
                            value: string | undefined;
                          }> = [];
                          let isMulti = field["type"] === "select-multiple";
                          if (
                            field.options !== undefined &&
                            field.options.values !== undefined
                          ) {
                            field.options.values.forEach((elt: any) => {
                              possibleValues?.push({
                                label: elt.label,
                                value: elt.value,
                              });
                            });
                          }

                          return (
                            <Select
                              options={possibleValues}
                              onChange={(values: any) => {
                                let filterValue;
                                if (values instanceof Array) {
                                  filterValue = values.map(
                                    (value) => value.value
                                  );
                                } else {
                                  filterValue = values?.value || null;
                                }
                                setFieldValue(field.name, values, filterValue);
                              }}
                              value={getFieldValue(field.name)}
                              isMulti={isMulti}
                              loadingMessage={() => "Chargement en cours..."}
                              noOptionsMessage={() =>
                                "Aucune valeur à sélectionner"
                              }
                              placeholder=""
                              className={"input-" + field.name}
                            />
                          );
                        case "text":
                          return (
                            <Form.Control
                              type="text"
                              name={field.name}
                              value={getFieldValue(field.name) || ""}
                              onChange={(e: any) =>
                                setFieldValue(field.name, e.target.value)
                              }
                              className={"input-" + field.name}
                            />
                          );

                        default:
                          if (field.options?.relatedTo?.field) {
                            field.options.relatedTo.getValues = (): any[] => {
                              let values = getFieldValue(
                                field.options?.relatedTo.field
                              );

                              // Autocomplete multivalued [{value:'foo',label:'bar'},{value:'foo2',label:'bar2'}]
                              if (values && values instanceof Array) {
                                values = values.map(
                                  (value: any) => value?.value
                                );
                                return values || [];
                              }
                              // Autocomplete monovalued {value:'foo',label:'bar'}
                              else if (values?.value) {
                                return [values?.value];
                              } else {
                                return [];
                              }
                            };
                          }

                          return (
                            <AutocompleteField
                              field={field}
                              onSelect={(values: any) => {
                                let filterValue;
                                if (values instanceof Array) {
                                  filterValue = values.map(
                                    (value) => value.value
                                  );
                                } else {
                                  filterValue = values?.value || null;
                                }
                                setFieldValue(field.name, values, filterValue);
                              }}
                              value={getFieldValue(field.name)}
                            />
                          );
                      }
                    })()}
                  </Col>
                </Form.Group>
              </Col>
            );
          })}
        </Row>
        {hasSeeMoreOption && (
          <Row>
            {!seeMore && (
              <Col className="text-center">
                <Button
                  onClick={() => {
                    setSeeMore(true);
                  }}
                  variant="outline-primary"
                  size="sm"
                >
                  Voir plus de filtres
                </Button>
              </Col>
            )}
            {seeMore && (
              <Col className="text-center">
                <Button
                  onClick={() => {
                    setSeeMore(false);
                  }}
                  variant="outline-primary"
                >
                  Voir moins de filtres
                </Button>
              </Col>
            )}
          </Row>
        )}
        <Row>
          <Col className="text-right">
            <Button
              onClick={() => {
                resetSearch();
              }}
              variant="secondary"
              className="mr-2"
            >
              <FontAwesomeIcon icon={faUndo} />
              Réinitialiser
            </Button>
            <Button
              onClick={() => {
                search();
              }}
            >
              <FontAwesomeIcon icon={faSearch} /> Rechercher
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchForm;
